import { currentBrand, type AdminPanelBrandName } from '@/helpers/brands'

type Environment = 'dev' | 'qa' | 'prod'

interface EnvConfig {
    hostname: string | string[]
    backendHostname: string
}

type EnvConfigsMap = {
    [k in Environment]: EnvConfig
}

type DomainConfig = {
    facebookId?: string
    gaId?: string
    tiktokIds?: string[]
    analyticsKey?: string
    inviteLinkUrl?: string
}

type BrandConfigMap = {
    [k in Environment]?: {
        [domain in AdminPanelBrandName]?: DomainConfig
    }
}

const envConfigs: EnvConfigsMap = {
    dev: {
        hostname: [
            'devagency.livebalance.biz',
            'devpride.livebalance.biz',
            'dev.pridebalance.net',
            'devjoola.livebalance.biz'
        ],
        backendHostname: 'videomeetlab.com'
    },
    qa: {
        hostname: [
            'qaagency.livebalance.biz',
            'qapride.livebalance.biz',
            'qa.pridebalance.net',
            'qajoola.livebalance.biz'
        ],
        backendHostname: 'qastreaminfra.com'
    },
    prod: {
        hostname: [
            'agency.livebalance.biz',
            'livebalance.biz',
            'pride.livebalance.biz',
            'pridebalance.net',
            'joola.livebalance.biz'
        ],
        backendHostname: 'prlivechat.com'
    }
}

const brandConfigs: BrandConfigMap = {
    dev: {
        livebalance: {
            analyticsKey: '643928b25c367b0fe618952d'
        },
        pridebalance: {
            analyticsKey: '659c1732387b260f0974b57f'
        },
        joola: {
            inviteLinkUrl: 'https://jooladev.com?inviteToken={token}',
            analyticsKey: ''
        }
    },
    qa: {
        livebalance: {
            analyticsKey: '64413caba1cb0215fb9ccee3'
        },
        pridebalance: {
            analyticsKey: '659d0f244b0f0b5ce55b3360'
        },
        joola: {
            inviteLinkUrl: 'https://jooladev.com?inviteToken={token}',
            analyticsKey: ''
        }
    },
    prod: {
        livebalance: {
            facebookId: '3175113282786077',
            gaId: 'G-3V7KMXBHK4',
            tiktokIds: ['CL142DRC77UBVPRAJKNG', 'CL141L3C77U3K90HNG30'],
            analyticsKey: '64413cc94288998ed749e962'
        },
        pridebalance: {
            analyticsKey: '659bed562269d27b518a6a57'
        },
        joola: {
            inviteLinkUrl: 'https://joola.co?inviteToken={token}',
            analyticsKey: ''
        }
    }
}

let env: Environment = 'dev'

if (Object.keys(envConfigs).includes(localStorage.env)) {
    env = localStorage.env
} else {
    for (const [envName, config] of Object.entries(envConfigs)) {
        let isHostnameMatched = false

        if (Array.isArray(config.hostname)) {
            isHostnameMatched = config.hostname.some(
                (hostname) => hostname === location.hostname
            )
        } else {
            isHostnameMatched = location.hostname === config.hostname
        }

        if (isHostnameMatched) {
            env = envName as Environment
            break
        }
    }
}

interface Locale {
    code: string
    default?: boolean
    text: string
}

interface Config {
    env: Environment
    locales: Locale[]
    generalPayOutThreshold: number
}

const config: Config & EnvConfig & DomainConfig = {
    env,
    generalPayOutThreshold: 20000,
    locales: [
        { text: 'English', code: 'en', default: true },
        { text: 'Russian', code: 'ru' },
        { text: 'Spanish', code: 'es' },
        { text: 'Portuguese', code: 'pt-br' },
        { text: 'Arabic', code: 'ar' },
        { text: 'Vietnamese', code: 'vi' },
        { text: 'French', code: 'fr' }
    ],
    ...envConfigs[env],
    ...(brandConfigs[env]?.[currentBrand.brandName] || {})
}

export default config
