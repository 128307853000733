import { computed, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { debounce } from '@/helpers/general'

export function useBreakPoints() {
    const store = useStore()

    const windowWidth = computed<number>(() => store.getters.windowWidth)
    const isMobile = computed<boolean>(() => windowWidth.value < 1280)
    const isDesktop = computed<boolean>(() => windowWidth.value >= 1280)

    function setWindowWidth() {
        store.commit('setWindowWidth')
    }

    const debouncedResize = debounce(setWindowWidth, 200)


    function initBreakpoints() {
        window.addEventListener(
            'resize',
            debouncedResize
        )
    }

    onBeforeUnmount(() => {
        window.removeEventListener(
            'resize',
            debouncedResize
        )
    })

    return {
        initBreakpoints,
        isMobile,
        isDesktop,
    }
}
