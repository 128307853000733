
import { defineComponent, computed } from 'vue'
import { useUser } from '@/composables/user'

export default defineComponent({
    props: {
        src: {
            type: String,
            default: ''
        },
        size: {
            type: [Number, String],
            default: 40
        },
        self: {
            type: Boolean,
            default: false
        },
        onlineStatus: {
            type: String,
            default: 'offline',
            validator(value: string) {
                const knownStatuses = ['online', 'inacall', 'offline']

                return knownStatuses.includes(value)
            }
        },
        gradientBorder: {
            type: Boolean,
            default: false
        }
    },
    emits: ['click'],
    setup({ src, self, onlineStatus }) {
        const { userAvatarSrc } = useUser()

        const avatarSrc = computed<string>(() => self ? userAvatarSrc.value : src)
        const isOnlineStatusShown = onlineStatus && onlineStatus !== 'offline'
        const gradientKey = `lg-${Date.now()}`

        return {
            avatarSrc,
            isOnlineStatusShown,
            gradientKey,
        }
    },
})
