import axios, { AxiosRequestConfig } from 'axios'
import config from '@/config'
import { authToken, user } from '@/helpers/user'
import { storageJsonParser } from '@/helpers/general'
import authHelper from '@/helpers/auth'

const coreServicePort = 3000
const adminBackServicePort = 3011
const analyticskServicePort = 3016

const headers: any = {
    os: 'web'
}

const coreOptions: AxiosRequestConfig = {
    baseURL: `https://${config.backendHostname}:${coreServicePort}`,
    headers
}

const adminBackOptions: AxiosRequestConfig = {
    baseURL: `https://${config.backendHostname}:${adminBackServicePort}`,
    headers
}

const analyticsOptions: AxiosRequestConfig = {
    baseURL: `https://${config.backendHostname}:${analyticskServicePort}`,
    headers
}

if (authToken) {
    coreOptions.headers!.authorization = `Bearer ${authToken}`
    adminBackOptions.headers!.authorization = `Bearer ${authToken}`
    analyticsOptions.headers!.authorization = `Bearer ${authToken}`
}

const axiosCore = axios.create(coreOptions)
const axiosAdminBack = axios.create(adminBackOptions)
const axiosAnalytics = axios.create(analyticsOptions)

const axiosInstances = [axiosCore, axiosAdminBack, axiosAnalytics]

axiosInstances.forEach((service) => {
    service.interceptors.request.use(
        config => {
            const loggedInSupport = storageJsonParser('support')

            if (loggedInSupport) {
                config.headers!['as-agency-id'] = user.profile._id
            }

            return config
        }
    )

    service.interceptors.response.use(
        (response) => response.data,
        (error) => {
            if (error?.response?.status === 488) {
                authHelper.logout()
            }

            if (error?.response?.status === 483) {
                localStorage.removeItem('user')
                localStorage.removeItem('lastActivity')
                location.href = `${location.origin}?banned=true`
            }
            throw error
        }
    )
})

function setAuthToken(authToken: string) {
    return axiosInstances.forEach((service) => {
        service.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
    })
}

export { axiosCore, axiosAdminBack, axiosAnalytics, setAuthToken }
