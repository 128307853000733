
import { defineComponent, onBeforeUnmount } from 'vue'
import { useBreakPoints } from '@/composables/breakpoints'

import UiIcon from '@/components/icons/index.vue'

interface IconInfo {
    name: string,
    color: string,
    background: string
}

interface TypesIconsMap {
    [k: string]: IconInfo
}

const typesIconsMap: TypesIconsMap = {
    success: {
        name: 'check',
        color: 'var(--freedom-120)',
        background: 'var(--freedom-10)'
    },
    error: {
        name: 'alert-circle',
        color: 'var(--poppy-120)',
        background: 'var(--poppy-10)'
    }
}

export default defineComponent({
    components: {
        UiIcon,
    },
    props: {
        text: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'success',
            validator(value: string): boolean {
                const expectedValues = ['success', 'error']

                return expectedValues.includes(value)
            }
        },
        duration: {
            type: Number,
            default: 3000
        }
    },
    emits: ['close'],
    setup(props, { emit }) {
        const { isMobile } = useBreakPoints()

        const iconInfo: IconInfo = typesIconsMap[props.type]

        function close() {
            emit('close')
        }

        const closeTimeout = setTimeout(close, props.duration)

        onBeforeUnmount(() => {
            clearTimeout(closeTimeout)
        })

        return {
            isMobile,
            iconInfo,
            close,
        }
    }
})
