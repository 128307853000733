
import { defineComponent, ref, computed, onBeforeUnmount } from 'vue'
import { useBreakPoints } from '@/composables/breakpoints'

import UiIcon from '@/components/icons/index.vue'
import UiAvatar from '@/components/ui/Avatar.vue'

export default defineComponent({
    components: {
        UiIcon,
        UiAvatar,
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        duration: {
            type: Number,
            default: 5000
        },
        text: {
            type: String,
            default: ''
        },
        avatar: {
            type: String,
            default: ''
        },
        actionText: {
            type: String,
            default: ''
        },
        actionCallback: {
            type: Function,
            required: false
        },
        accent: {
            type: String,
            default: '',
            validator(value: string): boolean {
                const expectedValues = ['success', 'error']

                return !value || expectedValues.includes(value)
            }
        }
    },
    emits: ['close'],
    setup(props, { emit }) {
        const { isMobile } = useBreakPoints()

        function onActionClick() {
            if (props.actionCallback) {
                props.actionCallback()
            }

            emit('close')
        }

        function onClose() {
            emit('close')
        }

        const tickMs = 100
        const timeLeft = ref<number>(props.duration)
        const progress = computed<number>(() => timeLeft.value * 100 / props.duration)

        const progressInterval = setInterval(() => {
            timeLeft.value -= tickMs

            if (timeLeft.value <= 0) {
                onClose()
            }
        }, tickMs)

        onBeforeUnmount(() => {
            clearInterval(progressInterval)
        })

        return {
            isMobile,
            progress,
            onActionClick,
            onClose
        }
    }
})
