let ttq

function load(w, d, t) {
    w.TiktokAnalyticsObject = t

    ttq = (w[t] = w[t] || [])
    ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"]
    ttq.setAndDefer = function (t, e) {
        t[e] = function () {
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)))
        }
    }

    for (let i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i])

    ttq.instance = function (t) {
        for (let e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++) ttq.setAndDefer(e, ttq.methods[n]);
        return e;
    }

    ttq.load = function (e, n) {
        let i = "https://analytics.tiktok.com/i18n/pixel/events.js";
        (ttq._i = ttq._i || {}), (ttq._i[e] = []), (ttq._i[e]._u = i), (ttq._t = ttq._t || {}), (ttq._t[e] = +new Date()), (ttq._o = ttq._o || {}), (ttq._o[e] = n || {});
        n = document.createElement("script");
        (n.type = "text/javascript"), (n.async = !0), (n.src = i + "?sdkid=" + e + "&lib=" + t);
        e = document.getElementsByTagName("script")[0];
        e.parentNode.insertBefore(n, e);
    }
}

export function init(tiktokIds) {
    load(window, document, "ttq")

    for (const id of tiktokIds) {
        ttq.load(id)
    }

    ttq.page()
}

export function track(eventName, options) {
    ttq?.track?.(eventName, options)
}
