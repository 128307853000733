
import { defineComponent, defineAsyncComponent, shallowRef, ref, computed, onMounted, watch } from 'vue'

export default defineComponent({
    props: {
        icon: {
            type: String,
            required: true
        },
        color: {
            type: String,
        },
        width: {
            type: [Number, String],
            default: 24
        },
        height: {
            type: [Number, String],
            default: 24
        },
        hoverColor: String,
        transition: {
            type: String,
            default: '.3'
        }
    },
    setup(props) {
        const iconComponent = shallowRef(null)
        const isFocus = ref(false)

        const iconColor = computed(() => isFocus.value ? props.hoverColor : props.color)

        function iconLoader(iconName: string) {
            if (!iconName) {
                console.warn('No icon name')
                return
            }

            return defineAsyncComponent(() => import(`@/components/icons/${iconName}.vue`))
        }

        function onMouseover() {
            if (!props.hoverColor) return
            isFocus.value = true
        }

        function onMouseleave() {
            if (!props.hoverColor) return
            isFocus.value = false
        }

        watch(() => props.icon, () => {
            iconComponent.value = iconLoader(props.icon)
        })

        onMounted(() => {
            iconComponent.value = iconLoader(props.icon)
        })

        return {
            iconComponent,
            isFocus,
            iconColor,
            iconLoader,
            onMouseover,
            onMouseleave,
        }
    }
})
