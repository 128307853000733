const user = (localStorage.user && JSON.parse(localStorage.user)) || {}
const userId: string = user.profile?._id
const authToken: string = user.authData?.token
const isAuth: boolean = Boolean(authToken)
const onboardingScreens =
    (localStorage[`onboardingScreens_${userId}`] &&
        JSON.parse(localStorage[`onboardingScreens_${userId}`])) ||
    []
const isTermsAccepted: boolean =
    Boolean(localStorage.support) || onboardingScreens.includes('terms')
const isAccountCreated: boolean =
    Boolean(localStorage.support) || onboardingScreens.includes('billing')

const getAge = (date: string) => {
    const birthday = new Date(date)
    const now = new Date()

    const age = now.getUTCFullYear() - birthday.getUTCFullYear()

    const monthsDiff = now.getUTCMonth() - birthday.getUTCMonth()
    if (monthsDiff !== 0) {
        return monthsDiff < 0 ? age - 1 : age
    }

    const daysDiff = now.getUTCDate() - birthday.getUTCDate()
    if (daysDiff !== 0) {
        return daysDiff < 0 ? age - 1 : age
    }

    return age
}

export {
    user,
    userId,
    authToken,
    isAccountCreated,
    isTermsAccepted,
    isAuth,
    getAge
}
