import { createStore } from 'vuex'
import { deviceStore, DeviceState } from './device'
import { pageStore, PageState } from './page'
import { visibilityStore, VisibilityState } from './visibility'
import { authStore, AuthState } from './auth'
import { supportInfoStore, SupportInfoState } from './support-info'
import { streamersStore, StreamersState } from './streamers'
import { profileStore, ProfileState } from './profile'
import { logsStore, LogsState } from './logs'
import { paymentTargetsStore, PaymentTargetsState } from './payments-targets'

export interface RootState {
	device: DeviceState
	page: PageState
	visibility: VisibilityState
	auth: AuthState
	supportInfo: SupportInfoState
	streamers: StreamersState
	profile: ProfileState
	logs: LogsState
	paymentTargetsStore: PaymentTargetsState
}

export default createStore({
	modules: {
		deviceStore,
		pageStore,
		visibilityStore,
		authStore,
		supportInfoStore,
		streamersStore,
		profileStore,
		logsStore,
		paymentTargetsStore
	}
})
