import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6272d987"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ui-notification d-flex ai-center pr-4 shadow-m" }
const _hoisted_2 = { class: "text-content footnote mx-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIcon = _resolveComponent("UiIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "icon-wrapper d-flex jc-center ai-center",
      style: _normalizeStyle({ background: _ctx.iconInfo.background })
    }, [
      _createVNode(_component_UiIcon, {
        icon: _ctx.iconInfo.name,
        color: _ctx.iconInfo.color,
        width: !_ctx.isMobile? 24 : 20,
        height: !_ctx.isMobile? 24 : 20
      }, null, 8, ["icon", "color", "width", "height"])
    ], 4),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.text), 1),
    _createElementVNode("button", {
      class: "close-btn",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
    }, [
      _createVNode(_component_UiIcon, {
        icon: "close",
        color: "var(--day-80)",
        width: !_ctx.isMobile? 24 : 20,
        height: !_ctx.isMobile? 24 : 20
      }, null, 8, ["width", "height"])
    ])
  ]))
}