import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { isAccountCreated, isAuth, isTermsAccepted } from '@/helpers/user'
import { isMobile } from '@/helpers/device'
import desktopRoutes from '@/router/desktop'
import mobileRoutes from '@/router/mobile'

import { LandingType } from '@/composables/analytics'

declare module 'vue-router' {
    interface RouteMeta {
        title?: string
        layout?: string
        parent?: string
        subheaderName?: string
        hideBottomNav?: boolean
        hasBackBtn?: boolean
        headerLink?: 'notifications' | 'settings'
        landingType?: LandingType
    }
}

const checkSignupPageByToken = () => {
    const { search, pathname } = window.location
    const params = new URLSearchParams(search)
    const isSignupPathname = (pathname === '/signup') || (pathname === '/alt/signup')
    return isSignupPathname && params.has('token')
}

const routes: Array<RouteRecordRaw> = []

if (!isAuth || checkSignupPageByToken()) {
    routes.push(
        {
            path: '/proxy',
            name: 'proxy',
            component: () => import('@/pages/proxy.vue'),
            meta: {
                layout: 'empty'
            }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/pages/login.vue'),
            meta: {
                title: 'Login',
                layout: 'auth'
            }
        },
        {
            path: '/signup',
            name: 'signup',
            component: () => import('@/pages/signup.vue'),
            meta: {
                layout: 'signup',
                landingType:  LandingType.OldAgentForm
            }
        },
        {
            path: '/credential-page',
            name: 'credential-page',
            component: () => import('@/pages/credential-page.vue'),
            meta: {
                layout: 'signup',
                landingType:  LandingType.OldAgentForm
            }
        },

        {
            path: '/alt/signup',
            name: 'alt-signup',
            component: () => import('@/pages/alt/signup.vue'),
            meta: {
                layout: 'signupAlt',
                landingType:  LandingType.New5DollarForm
            }
        },
        {
            path: '/alt/credential-page',
            name: 'alt-credential-page',
            component: () => import('@/pages/alt/credential-page.vue'),
            meta: {
                layout: 'signupAlt',
                landingType:  LandingType.New5DollarForm
            }
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: () => ({ name: 'login' })
        }
    )
}

if (isAuth && !isAccountCreated) {
    routes.push(
        {
            path: '/create-account',
            name: 'create-account',
            component: () => import('@/pages/create-account.vue'),
            meta: {
                title: 'Create Account',
                layout: 'auth'
            }
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: () => ({ name: 'create-account' })
        }
    )
}

if (isAuth && !isTermsAccepted) {
    routes.push(
        {
            path: '/terms',
            name: 'terms',
            component: () => import('@/pages/terms.vue'),
            meta: {
                title: 'Terms',
                layout: 'auth'
            }
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: () => ({ name: 'terms' })
        }
    )
}

if (isAuth) {
    routes.push(...(isMobile ? mobileRoutes : desktopRoutes))
}

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
