import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76d5348a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toasts-pool" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiToast = _resolveComponent("UiToast")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toastsPool, (toast, idx) => {
      return (_openBlock(), _createBlock(_component_UiToast, {
        key: toast.id!,
        "data-key": toast.id!,
        title: toast?.title,
        text: toast?.text,
        avatar: toast?.avatar,
        accent: toast?.accent,
        duration: toast.duration,
        actionText: toast?.actionText,
        actionCallback: toast?.actionCallback,
        class: _normalizeClass(`type-${toast.type}`),
        style: _normalizeStyle({ top: `${_ctx.toastsOffset[idx]}px` }),
        onClose: ($event: any) => (_ctx.removeToast(toast.id!))
      }, null, 8, ["data-key", "title", "text", "avatar", "accent", "duration", "actionText", "actionCallback", "class", "style", "onClose"]))
    }), 128))
  ]))
}