import { Plugin, Ref, ref, computed } from 'vue'
import { getPropValue } from '@/helpers/data'
import { IObject } from '@/interfaces/common'

const pluralizationRules: PluralizationRules = {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    'ru': function (choice: number, choicesLength: number) {
        if (choice === 0) {
            return 0;
        }

        const teen = choice > 10 && choice < 20;
        const endsWithOne = choice % 10 === 1;

        if (choicesLength < 4) {
            return (!teen && endsWithOne) ? 1 : 2;
        }
        if (!teen && endsWithOne) {
            return 1;
        }
        if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
            return 2;
        }

        return (choicesLength < 4) ? 2 : 3;
    }
}

function getAppLang(): string {
    if (navigator.language) {
        return navigator.language.split('-')[0]
    } else if (navigator.languages && navigator.languages.length > 0) {
        return navigator.languages[0].split('-')[0]
    }

    return ''
}

export class Localization {
    locales: string[]
    locale: Ref<string>
    dirPath: string
    storageKey: string = ''
    dictionary: { [locale: string]: IObject } = {}
    pluralizationRules: PluralizationRules

    constructor(
        { locales, locale, dirPath, storageKey, pluralizationRules }:
        { locales: string[], locale: string, dirPath: string, storageKey?: string, pluralizationRules: PluralizationRules }
    ) {
        this.locales = locales
        this.dirPath = dirPath
        this.pluralizationRules = pluralizationRules
        
        const appLang = getAppLang()

        if (locales.includes(appLang)) {
            locale = appLang
        }

        if (storageKey) {
            this.storageKey = storageKey

            const storedLocale = localStorage[`locale_${storageKey}`]

            if (locales.includes(storedLocale)) {
                locale = localStorage[`locale_${storageKey}`]
            }
        }

        this.locale = ref(locale)
    }

    async loadLocaleResource(locale: string) {
        const { default: translations } = await import(`@/${this.dirPath}/${locale}.json`)

        this.dictionary[locale] = translations
    }

    async switchLocale(locale: string) {
        if (!this.locales.includes(locale)) {
            console.error(`Unexpected locale - ${locale}`)
        }

        await this.loadLocaleResource(locale)
            .then(() => {
                this.locale.value = locale
                
                if (this.storageKey) {
                    localStorage[`locale_${this.storageKey}`] = locale
                }
            })
            .catch(console.error)
    }

    async init() {
        await this.loadLocaleResource(this.locale.value)
    }

    translate(key: string) {
        return computed<string>(() => getPropValue(this.dictionary[this.locale.value], key) || '')
    }

    translatePlural(key: string, choice: number) {
        let propValue = getPropValue(this.dictionary[this.locale.value], key)

        if (propValue) {
            const choices = propValue.split('|');

            choice = this.getChoiceIndex(choice, choices.length);
            propValue = choices[choice] ? choices[choice].trim() : null
        }

        return computed<string>(() => propValue || '')
    }

    getChoiceIndex (choice: number, choicesLength: number) {

        // Default getChoiceIndex implementation (for example english)
        const defaultImpl = function (_choice: number, _choicesLength: number) {
            _choice = Math.abs(_choice);
      
            if (_choicesLength === 2) {
              return _choice
                ? _choice > 1
                  ? 1
                  : 0
                : 1
            }
      
            return _choice ? Math.min(_choice, 2) : 0
        }

        if (this.locale.value in this.pluralizationRules) {
          return this.pluralizationRules[this.locale.value](choice, choicesLength)
        } else {
          return defaultImpl(choice, choicesLength)
        }
      }
}


export type LocalizationsMap = {
    [scopeName: string]: Localization
}

const localizations: LocalizationsMap = {}

interface GetChoiceIndex {
    (choice: number, choicesLength: number) : number
}

interface PluralizationRules {
    [lang: string]: GetChoiceIndex,
}

interface LocaleScope {
    name: string,
    dirPath: string,
    cached: boolean,
}

interface PluginOptions {
    locale: string,
    locales: string[],
    scopes: LocaleScope[],
}

const localizationPlugin: Plugin = {
    async install(app, { locale = 'en', locales = [], scopes = []}: PluginOptions) {
        for (const scope of scopes) {
            const localization = new Localization({
                locales,
                locale,
                dirPath: scope.dirPath,
                storageKey: scope.cached ? scope.name : '',
                pluralizationRules
            })

            localization.init()

            localizations[scope.name] = localization
        }

        app.provide('localization', localizations)
    }
}

export default localizationPlugin