import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-309753bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = {
  key: 0,
  class: "h5 mb-1"
}
const _hoisted_3 = {
  key: 1,
  class: "caption-1"
}
const _hoisted_4 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIcon = _resolveComponent("UiIcon")!
  const _component_UiAvatar = _resolveComponent("UiAvatar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['ui-toast d-flex ai-center py-3 pl-4 pr-9 br-2 shadow-s', _ctx.accent ? `accent-${_ctx.accent}` : ''])
  }, [
    (!_ctx.actionText)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: "close-btn pointer",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
        }, [
          _createVNode(_component_UiIcon, {
            icon: "close",
            color: "var(--alpha-day-32)"
          })
        ]))
      : _createCommentVNode("", true),
    (_ctx.avatar)
      ? (_openBlock(), _createBlock(_component_UiAvatar, {
          key: 1,
          src: _ctx.avatar,
          size: !_ctx.isMobile ? 64 : 48,
          class: "mr-3"
        }, null, 8, ["src", "size"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("h5", _hoisted_2, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      (_ctx.text)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.actionText)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          class: "action-btn d-flex jc-center ai-center py-2 px-6 primary--bg day-100--text bold",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onActionClick && _ctx.onActionClick(...args)))
        }, [
          _createElementVNode("div", {
            class: "progress",
            style: _normalizeStyle({ width: `${_ctx.progress}%` })
          }, null, 4),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.actionText), 1)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}