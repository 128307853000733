import { ref } from 'vue'
import { useRoute } from 'vue-router'

import InternalAnalytics from '@/plugins/internal-analytics'
import analyticSignupHelpers from '@/helpers/analytics/signup'
import config from '@/config'

interface IParameters {
    [key: string]: string | number
}

export enum LandingType {
    OldAgentForm = 'old_agent_form',
    New5DollarForm = 'new_5dollar_form'
}

const AnalyticsInstance = new InternalAnalytics(config.backendHostname)

const URLParameters = ref<IParameters>({})
const pageEvent = ref<string>('')
const analyticsKey = ref<string | null>('')

export function useAnalytics() {
    const route = useRoute()

    function initAnalytics(landKey: string) {
        analyticsKey.value = landKey
        AnalyticsInstance.loadLandingScript(landKey)
    }

    function setPageEvent(eventName: string) {
        pageEvent.value = eventName
    }

    function getURLParameters(url: any) {
        const queryString = url
            ? url.split('?')[1]
            : window.location.search.slice(1)
        const arr: string[] = queryString.match(/([^?=&]+)(=([^&]*))/g) || []

        URLParameters.value = arr.reduce(
            (acc: { [key: string]: string }, el: string) => {
                acc[el.slice(0, el.indexOf('='))] = el.slice(
                    el.indexOf('=') + 1
                )
                return acc
            },
            {}
        )
    }

    function trackIA(event: string, payload: any = {}) {
        if (!analyticsKey.value || !analyticSignupHelpers[event]) {
            return
        }

        const landingType = route.meta?.landingType
        if(!!landingType) {
            payload.landing_type = landingType
        }

        const paramsWithValue = analyticSignupHelpers[event].reduce(
            (acc: IParameters, item: string) => {
                const value = URLParameters.value[item] || payload[item]
                if (value !== undefined) {
                    acc[item] = value
                }

                return acc
            },
            {}
        )

        const data = {
            eventCategory: pageEvent.value,
            event,
            params: paramsWithValue
        }

        AnalyticsInstance.activity(data)
    }

    function setAnalyticsSessionStorage(data: any) {
        sessionStorage.setItem('analytics', JSON.stringify(data))
    }

    return {
        initAnalytics,
        trackIA,
        getURLParameters,
        setAnalyticsSessionStorage,
        setPageEvent,
        URLParameters
    }
}
