import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d4641f9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notifications-pool" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiNotification = _resolveComponent("UiNotification")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notificationsPool, (notification, idx) => {
      return (_openBlock(), _createBlock(_component_UiNotification, {
        key: notification.id!,
        "data-key": notification.id!,
        text: notification.text,
        type: notification?.type,
        duration: notification?.duration,
        style: _normalizeStyle({ bottom: `${_ctx.notificationsOffset[idx]}px` }),
        onClose: ($event: any) => (_ctx.removeNotification(notification.id!))
      }, null, 8, ["data-key", "text", "type", "duration", "style", "onClose"]))
    }), 128))
  ]))
}