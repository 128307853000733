import { ref, computed, Ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useUser } from '@/composables/user'
import { Notification, Toast, ToastActions, ToastActivities } from '@/interfaces/notification'
import { Profile } from '@/interfaces/user'

interface SimpleToastParams {
    title?: string,
    text?: string
}

interface ActionToastParams<T> {
    user: Profile,
    action: T
}

type ToastActionsInfoMap = {
    [key in ToastActions]: SimpleToastParams
}

interface ToastActivityInfo {
    title: string,
    text: string,
    actionText: string,
    actionCallback(userId: string): void
}

type ToastActivitiesInfoMap = {
    [key in ToastActivities]: ToastActivityInfo
}

interface NotificationComposable {
    toastsPool: Ref<Toast[]>,
    notificationsPool: Ref<Notification[]>,
    showNotification(notification: Notification): void,
    removeNotification(notificationId: number): void,
    showToast(toast: Toast): void,
    removeToast(toastId: number): void
    showSuccessToast(params: SimpleToastParams): void,
    showErrorToast(params: SimpleToastParams): void,
    showActionToast(params: ActionToastParams<ToastActions>): void,
    showActivityToast(params: ActionToastParams<ToastActivities>): void,
}

const toastsPool = ref<Toast[]>([])
const notificationsPool = ref<Notification[]>([])

export function useNotification(): NotificationComposable {
    const router = useRouter()
    const store = useStore()
    const { getAvatarPhoto } = useUser()

    const visiblePool = computed(() => store.getters.visiblePool)

    const toastActionsInfoMap: ToastActionsInfoMap = {
        [ToastActions.like]: {
            title: 'You liked {username}'
        },
        [ToastActions.beLiked]: {
            title: '{username}',
            text: 'liked you'
        },
        [ToastActions.follow]: {
            title: 'You started to follow {username}'
        },
        [ToastActions.unfollow]: {
            title: 'You unfollowed from {username}'
        },
        [ToastActions.sendGift]: {
            title: 'You sent a gift to {username}.'
        },
        [ToastActions.report]: {
            title: '{username}',
            text: 'is Reported'
        },
        [ToastActions.block]: {
            title: '{username}',
            text: 'is Blocked'
        },
        [ToastActions.unblock]: {
            title: '{username}',
            text: 'is Unblocked'
        },
    }

    const toastActivitiesInfoMap: ToastActivitiesInfoMap = {
        [ToastActivities.missedCall]: {
            title: 'Missed Call',
            text: 'from {username}',
            actionText: 'View',
            actionCallback(userId: string) {
                router.push({ name: 'user-detail', params: { userId } })
            }
        },
        [ToastActivities.newMessage]: {
            title: '{username}',
            text: 'sent you a message',
            actionText: 'Read',
            actionCallback(userId: string) {
                router.push({ name: 'chat', query: { userId } })
            }
        },
        [ToastActivities.gift]: {
            title: '{username}',
            text: 'sent you a gift',
            actionText: 'View',
            actionCallback(userId: string) {
                router.push({ name: 'chat', query: { userId } })
            }
        },
    }

    function showNotification(notification: Notification): void {
        notification.id = Math.round(Math.random() * 1000_000_000)

        notificationsPool.value = [notification, ...notificationsPool.value]
    }

    function removeNotification(notificationId: number): void {
        const notificationEl: HTMLElement|null = document.querySelector(`.notifications-pool [data-key="${notificationId}"]`)

        if (notificationEl) {
            notificationEl.classList.add('disappearing')
        }

        setTimeout(() => {
            notificationsPool.value = notificationsPool.value.filter(notification => notification.id !== notificationId)
        }, 500)
    }

    function showToast(toast: Toast): void {
        const isActivityToastInPool = toastsPool.value.find((toast: Toast) => toast.type === 'activity')

        if (isActivityToastInPool) {
            return
        }

        if (toast.type === 'activity') {
            toastsPool.value.forEach((toast: Toast) => removeToast(toast.id!))
        }

        toast.id = Math.round(Math.random() * 1000_000_000)

        toastsPool.value = [toast, ...toastsPool.value]
    }

    function removeToast(toastId: number): void {
        const toastEl: HTMLElement|null = document.querySelector(`.toasts-pool [data-key="${toastId}"]`)

        if (toastEl) {
            toastEl.classList.add('disappearing')
        }

        setTimeout(() => {
            toastsPool.value = toastsPool.value.filter(toast => toast.id !== toastId)
        }, 500)
    }

    function showSuccessToast(params: SimpleToastParams): void {
        const toast: Toast = {
            type: 'default',
            accent: 'success',
            ...params
        }

        showToast(toast)
    }

    function showErrorToast(params: SimpleToastParams): void {
        const toast: Toast = {
            type: 'default',
            accent: 'error',
            ...params
        }

        showToast(toast)
    }

    function showActionToast(params: ActionToastParams<ToastActions>): void {
        const actionsInfo: SimpleToastParams = { ...toastActionsInfoMap[params.action] }
        const userName = params.user.name || 'User'

        if (actionsInfo.title) {
            actionsInfo.title = actionsInfo.title.replace('{username}', userName)
        }
        if (actionsInfo.text) {
            actionsInfo.text = actionsInfo.text.replace('{username}', userName)
        }

        const avatar: string = getAvatarPhoto(params.user.avatar)

        const toast: Toast = {
            type: 'default',
            avatar,
            ...actionsInfo
        }

        showToast(toast)
    }

    function showActivityToast(params: ActionToastParams<ToastActivities>): void {
        const activityInfo: ToastActivityInfo = { ...toastActivitiesInfoMap[params.action] }
        const userName = params.user.name || 'User'

        if (activityInfo.title) {
            activityInfo.title = activityInfo.title.replace('{username}', userName)
        }
        if (activityInfo.text) {
            activityInfo.text = activityInfo.text.replace('{username}', userName)
        }

        const avatar: string = getAvatarPhoto(params.user.avatar)

        const toast: Toast = {
            type: 'activity',
            avatar,
            ...activityInfo
        }

        if (activityInfo.actionCallback) {
            toast.actionCallback = () => {
                Object.keys(visiblePool.value).forEach(name => {
                    store.commit('removeVisibleItem', { name })
                })

                activityInfo.actionCallback(params.user._id)
            }
        }

        showToast(toast)
    }

    return {
        toastsPool,
        notificationsPool,
        showNotification,
        removeNotification,
        showToast,
        removeToast,
        showSuccessToast,
        showErrorToast,
        showActionToast,
        showActivityToast,
    }
}