const clickid = 'clickid'
const email = 'email'
const reason = 'reason'

const common = [
    'utm_source',
    'utm_campaign',
    'af_channel',
    'af_adset',
    'af_ad',
    'af_adset_id',
    'af_ad_id',
    'utm_content',
    'pid',
    'c'
]

const reg_page = [
    'landing_type'
]

const sign_up = [
    'agency',
    'gender',
    'name',
    'birthday',
    email,
    'phone',
    'country',
    'bio'
]

const store_redirect = [
    'ip',
    'geo',
    'browser',
    'incognito',
    'userAgent',
    'os',
    'oneLink',
    'referrerLink'
]

const events: { [key: string]: string[] } = {
    pageview_landing: [clickid, ...common, ...reg_page],
    click_sign_up_success: [clickid, ...common, ...sign_up, ...reg_page],
    click_sign_up_fail: [clickid, ...common, reason, ...reg_page],
    pageview_login_info: [clickid, ...common, email, ...reg_page],
    click_google_play: [...common, ...reg_page, ...store_redirect],
    left_landing_to_store: [...common, ...reg_page]
}

export default events
