interface LogoutParams {
    withReload?: boolean
}

function logout(params: LogoutParams = { withReload: true }) {
    localStorage.removeItem('user')
    localStorage.removeItem('support')
    localStorage.removeItem('lastActivity')

    if (params.withReload) {
        location.reload()
    }
}

export default {
    logout
}
