import { Module } from 'vuex'
import { RootState } from '@/store'
import { axiosAdminBack, axiosCore } from '@/plugins/axios'
import { PaymentTargetInfo } from '@/interfaces/payments-targets'

export interface PaymentTargetsState {
    streamersPaymentTargetsInfo: PaymentTargetInfo[]
}

export const paymentTargetsStore: Module<PaymentTargetsState, RootState> = {
    state: {
        streamersPaymentTargetsInfo: []
    },
    getters: {
        streamersPaymentTargetsInfo: state => state.streamersPaymentTargetsInfo,
    },
    mutations: {
        setStreamersPaymentTargetsInfo(state, streamersPaymentMethods: PaymentTargetInfo[]) {
            state.streamersPaymentTargetsInfo = streamersPaymentMethods
        },
        updateStreamerPaymentTargetInfo(state, { userId, recipient} ) {
            state.streamersPaymentTargetsInfo.forEach((item: PaymentTargetInfo) => {
                if (item._id === userId) {
                    item.recipient = recipient
                    item.billingMethod = null
                }
            })
        },
    },
    actions: {
        async getStreamersPaymentTargets({ commit }) {
            await axiosAdminBack.get<any, { paymentTargets: PaymentTargetInfo[] }>('/v2/agency/goddessPaymentTargets')
                .then(data => {
                    commit('setStreamersPaymentTargetsInfo', data.paymentTargets)
                })
        },
        async getStreamerBillingMethods(_, userId) {
            return await axiosCore.get('/billingMethods/list', { params: { userId } })
        },
        async updateStreamerPayTarget({ commit }, data) {
            const params = { recipient: data.recipient } as Record<string, string>

            if (data.billingMethodId) {
                params.billingMethodId = data.billingMethodId
            }

            return await axiosCore.post(`/billingMethods/payTarget?userId=${data.userId}`, params ).then((response) => {
                if (response.status) {
                    commit('updateStreamerPaymentTargetInfo', data)
                }
            })
        }
    },
}