
import { defineComponent, computed } from 'vue'
import { useBreakPoints } from '@/composables/breakpoints'
import { useNotification } from '@/composables/notification'

import UiNotification from '@/components/ui/Notification.vue'

export default defineComponent({
    components: {
        UiNotification
    },
    setup() {
        const { isMobile } = useBreakPoints()
        const { notificationsPool, removeNotification } = useNotification()

        const notificationsOffset = computed<number[]>(() => {
            const spaceBetweenNotifications = !isMobile.value ? 16 : 8
            const notificationHeight = !isMobile.value ? 56 : 48

            const notificationsOffset: number[] = []
            let currentOffset = 0

            for (let i = 0; i < notificationsPool.value.length; i++) {
                notificationsOffset.push(currentOffset)
                currentOffset += spaceBetweenNotifications + notificationHeight
            }

            return notificationsOffset
        })
    
        return {
            notificationsPool,
            notificationsOffset,
            removeNotification
        }
    }
})
