
import { StatusCalculated, Status } from '@/interfaces/data'
import { InviteItem, DayActivity } from '@/interfaces/streamer'

import { isWithinLastNDays } from '@/helpers/date'

function calculateStreamerStatus(streamer: InviteItem, streamerActivity: DayActivity[]): StatusCalculated {
    if (streamer.status === Status.Banned.toLowerCase()) return StatusCalculated.Banned
    if (streamer.status === Status.Pending.toLowerCase()) return StatusCalculated.Pending
    if (isWithinLastNDays(streamer.created, 28)) return StatusCalculated.Active
    if (!streamerActivity || !streamerActivity.length) return StatusCalculated.NonActive
    const sortedActivities = streamerActivity.sort((a: DayActivity, b: DayActivity) => a.date > b.date ? -1 : 1)
    const lastActivity = sortedActivities.find((dayActivity: DayActivity) => !!dayActivity.coinsTotal)
    if (!lastActivity) return StatusCalculated.NonActive
    const firstActivityBeforeLastWeek = sortedActivities.find((dayActivity: DayActivity) => !isWithinLastNDays(dayActivity.date, 7) && !!dayActivity.coinsTotal)
    if (firstActivityBeforeLastWeek &&
        isWithinLastNDays(lastActivity.date, 7) &&
        !isWithinLastNDays(firstActivityBeforeLastWeek.date, 28 + 7)) return StatusCalculated.Reactivated
    if (isWithinLastNDays(lastActivity.date)) return StatusCalculated.Active
    return StatusCalculated.NonActive
}
export {
    calculateStreamerStatus
}
