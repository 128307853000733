import { loadScriptDefault } from '@/helpers/analytics/scriptLoad.js'

function load(gtagId) {
    loadScriptDefault(`https://www.googletagmanager.com/gtag/js?id=${gtagId}`)
}

export function gtag() {
    if (!window.dataLayer) {
        return console.warn('dataLayer is missing')
    }

    window.dataLayer.push(arguments)
}

export function init(gtagId) {
    load(gtagId)

    window.dataLayer = window.dataLayer || []

    gtag('js', new Date())
    gtag('config', gtagId)
}
