import { resolveDynamicComponent as _resolveDynamicComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "ui-icon-wrapper d-flex",
    onMouseover: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseover && _ctx.onMouseover(...args))),
    onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onMouseleave && _ctx.onMouseleave(...args)))
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconComponent), {
      color: _ctx.iconColor,
      width: _ctx.width ? _ctx.width + 'px' : null,
      height: _ctx.height ? _ctx.height + 'px' : null,
      class: "ui-icon",
      style: _normalizeStyle({ '--transition-duration': `${_ctx.transition}s`, color: _ctx.iconColor })
    }, null, 8, ["color", "width", "height", "style"])),
    _createElementVNode("div", {
      class: "click-trap",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
    })
  ], 32))
}