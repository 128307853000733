import { RouteRecordRaw } from 'vue-router'
import { currentBrand } from '@/helpers/brands'

const defaultLayout = 'mobile'
let routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/pages/mobile/index.vue'),
        meta: {
            title: currentBrand.title
        }
    },
    {
        path: '/streamers',
        name: 'streamers',
        component: () => import('@/pages/mobile/streamers.vue'),
        meta: {
            title: 'general.streamers'
        }
    },
    {
        path: '/invites',
        name: 'invites',
        component: () => import('@/pages/mobile/invites.vue'),
        meta: {
            title: 'general.invites'
        }
    },
    {
        path: '/logs',
        name: 'logs',
        component: () => import('@/pages/mobile/logs.vue'),
        meta: {
            title: 'general.logs'
        }
    },
    {
        path: '/profile/:id',
        name: 'profile',
        component: () => import('@/pages/mobile/profile/_id.vue'),
        meta: {
            title: 'general.profile',
            hideBottomNav: true
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@/pages/mobile/notifications.vue'),
        meta: {
            title: 'general.notifications',
            hideBottomNav: true
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('@/pages/mobile/faq.vue'),
        meta: {
            title: 'general.faq'
        }
    },
    {
        path: '/faq/:id',
        name: 'tab-details',
        component: () => import('@/pages/mobile/tab-details.vue'),
        meta: {
            subRouteOf: 'faq',
            title: 'general.faq',
            hasBackBtn: true
        }
    },
    {
        path: '/payments-targets',
        name: 'payments-targets',
        component: () => import('@/pages/mobile/payments-targets.vue'),
        meta: {
            title: 'general.payment_targets'
        }
    },
    {
        path: '/payments-history',
        name: 'payments-history',
        component: () => import('@/pages/mobile/payments-history.vue'),
        meta: {
            title: 'general.payment_history',
            headerLink: 'settings',
            hasBackBtn: true
        }
    },
    {
        path: '/subagencies',
        name: 'subagencies',
        component: () => import('@/pages/mobile/subagencies.vue'),
        meta: {
            title: 'general.sub_agencies',
            hideBottomNav: true,
            headerLink: 'settings'
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/pages/mobile/settings/index.vue'),
        meta: {
            title: 'general.settings',
            parent: 'settings',
            hideBottomNav: true,
            hideNotificationButton: true
        }
    },
    {
        path: '/settings/payment',
        name: 'settings_payment',
        component: () => import('@/pages/mobile/settings/payment.vue'),
        meta: {
            title: 'general.withdrawal_method',
            parent: 'settings',
            hideBottomNav: true,
            hideNotificationButton: true
        }
    },
    {
        path: '/settings/personal-contacts',
        name: 'settings_personal-contacts',
        component: () =>
            import('@/pages/mobile/settings/personal-contacts.vue'),
        meta: {
            title: 'general.personal_contacts',
            parent: 'settings',
            hideBottomNav: true,
            hideNotificationButton: true
        }
    },
    {
        path: '/settings/passwords',
        name: 'settings_passwords',
        component: () => import('@/pages/mobile/settings/passwords.vue'),
        meta: {
            title: 'general.email_and_password',
            parent: 'settings',
            hideBottomNav: true,
            hideNotificationButton: true
        }
    },
    {
        path: '/settings/notifications',
        name: 'settings_notifications',
        component: () => import('@/pages/mobile/settings/notifications.vue'),
        meta: {
            title: 'general.notifications',
            parent: 'settings',
            hideBottomNav: true,
            hideNotificationButton: true
        }
    },
    {
        path: '/settings/language',
        name: 'settings_language',
        component: () => import('@/pages/mobile/settings/language.vue'),
        meta: {
            title: 'general.language',
            parent: 'settings',
            hideBottomNav: true,
            hideNotificationButton: true
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
]

routes = routes.map((item) => {
    item.meta = item.meta || {}

    if (!item.meta.layout) {
        item.meta.layout = defaultLayout
    }

    return item
})

export default routes
