import { Module } from 'vuex'
import { RootState } from '@/store'
import visibilityHelper from '@/helpers/visibility'

export interface VisibilityState {
    visibilityItems: { [key: string]: any }
    visiblePool: { [key: string]: any }
}

export const visibilityStore: Module<VisibilityState, RootState> = {
    state: {
        visibilityItems: visibilityHelper.visibilityItems,
        visiblePool: visibilityHelper.visiblePool,
    },
    getters: {
        visibilityItems: state => state.visibilityItems,
        visiblePool: state => state.visiblePool,
    },
    mutations: {
        addVisibleItem(state, { name, storageValue, data = null }) {
            const visiblePool = Object.assign({}, state.visiblePool)
            const visibleItem = state.visibilityItems[name]
            if (!visibleItem) {
                console.warn(`Visible item ${name} not found`)
                return
            }

            visibleItem.setData(data)

            visiblePool[name] = visibleItem
            state.visiblePool = visiblePool

            if (storageValue !== undefined) {
                visibleItem.setToStorage(storageValue)
            }
        },
        removeVisibleItem(state, { name }) {
            const visiblePool = Object.assign({}, state.visiblePool)
            const visibleItem = state.visibilityItems[name]

            visibleItem.setData(null)
            visibleItem.onRemove()

            delete visiblePool[name]

            state.visiblePool = visiblePool
        }
    },
}
