
import { defineComponent, computed } from 'vue'
import { useBreakPoints } from '@/composables/breakpoints'
import { useNotification } from '@/composables/notification'

import UiToast from '@/components/ui/Toast.vue'

export default defineComponent({
    components: {
        UiToast
    },
    setup() {
        const { isMobile } = useBreakPoints()
        const { toastsPool, removeToast } = useNotification()

        const toastsOffset = computed<number[]>(() => {
            const spaceBetweenToasts = 8
            const toastHeight = 70
            const toastHeightWithAvatar = isMobile.value ? 74 : 90

            const toastsOffset: number[] = []
            let currentOffset = 0

            for (const toast of toastsPool.value) {
                toastsOffset.push(currentOffset)
                currentOffset += spaceBetweenToasts + (toast.avatar ? toastHeightWithAvatar : toastHeight)
            }

            return toastsOffset
        })

        return {
            toastsPool,
            toastsOffset,
            removeToast
        }
    }
})
