import { loadScriptDefault } from '@/helpers/analytics/scriptLoad.js'

export function init(facebookId) {
    (function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = "2.0"
        n.queue = []

        loadScriptDefault(v)
    })(window, document, "script", "/js/fbevents.js");

    window.fbq('init', facebookId)
    window.fbq('track', 'PageView')
}

export function track(eventName, options) {
    if (!!window.fbq) {
        window.fbq("track", eventName, options)
    }
}
