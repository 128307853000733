import { Module } from 'vuex'
import { RootState } from '@/store'
import { axiosCore } from '@/plugins/axios'
import { Messenger } from '@/interfaces/ui'
import { SupportContacts } from '@/interfaces/user'

export interface SupportInfoState {
    supportContacts: Messenger[]
}

const defaultSupportContacts: SupportContacts = {
    telegramNickname: 'hr_star_a',
    phone: ' +46767585376',
    email: 'molly.streaming.ag@gmail.com',
    name: 'Molly'
}

export const supportInfoStore: Module<SupportInfoState, RootState> = {
    state: {
        supportContacts: [],
    },
    getters: {
        supportContacts: state => state.supportContacts,
    },
    mutations: {
        setSupportContacts(state, contacts: SupportContacts) {
            const supportContacts: Messenger[] = [
                { icon: 'email', link: `mailto:${contacts?.email}`, color: 'var(--day-80)', value: contacts?.email },
                { icon: 'whatsapp', link: `https://api.whatsapp.com/send?phone=${contacts?.phone}`, value: contacts?.phone },
                { icon: 'telegram', link: `https://t.me/${contacts?.telegramNickname}`, value: contacts?.telegramNickname  },
            ]

            state.supportContacts = supportContacts
        },
    },
    actions: {
        async loadSupportContacts({ commit }) {
            await axiosCore.get<any, SupportContacts>('/supportContacts')
                .then(contacts => {
                    commit('setSupportContacts', contacts || defaultSupportContacts)
                })
        }
    }
}
