import { Module } from 'vuex'
import { RootState } from '@/store'
import { IObject } from '@/interfaces/common'
import { TableFilters, TableSortings } from '@/interfaces/table'
import { DateRange } from '@/interfaces/data'

export interface PageState {
    subheaderTab: string | number | null
    pageFilters: TableFilters
    pageFiltersModel: IObject
    oldPageFiltersModel: IObject,
    pageSortings: TableSortings,
    dateRange: DateRange,
    selectedPeriods: DateRange[],
}

export const pageStore: Module<PageState, RootState> = {
    state: {
        subheaderTab: null,
        pageFilters: {},
        pageFiltersModel: {},
        oldPageFiltersModel: {},
        pageSortings: {},
        dateRange: ['', ''],
        selectedPeriods: [],
    },
    getters: {
        subheaderTab: state => state.subheaderTab,
        pageFilters: state => state.pageFilters,
        pageFiltersModel: state => state.pageFiltersModel,
        oldPageFiltersModel: state => state.oldPageFiltersModel,
        pageSortings: state => state.pageSortings,
        dateRange: state => state.dateRange,
        selectedPeriods: state => state.selectedPeriods,
    },
    mutations: {
        setSubheaderTab(state, payload: string | number | null) {
			state.subheaderTab = payload
		},
        setPageFilters(state, filters: TableFilters) {
            state.pageFilters = filters
        },
        setPageFiltersModel(state, filtersModel: IObject) {
            state.oldPageFiltersModel = {...state.pageFiltersModel};
            state.pageFiltersModel = filtersModel
        },
        setPageSortings(state, sortings: TableSortings) {
            state.pageSortings = sortings
        },
        setDateRange(state, dateRange: DateRange) {
            state.dateRange = dateRange
        },
        setSelectedPeriods(state, periods: DateRange[]) {
            function updateAndValidateDates(datesList: any) {
                function parseDate(dateStr: string): Date {
                    const [year, month, day] = dateStr.split('-').map(Number);
                    return new Date(Date.UTC(year, month - 1, day));
                }

                function formatDate(date: Date): string {
                    const year = date.getUTCFullYear();
                    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                    const day = String(date.getUTCDate()).padStart(2, '0');
                    return `${year}-${month}-${day}`;
                }

                function getDayOfWeek(date: Date): number {
                    // Returns the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
                    return date.getUTCDay();
                }

                function adjustDate(date: Date, days: number): Date {
                    const adjustedDate = new Date(date);
                    adjustedDate.setUTCDate(adjustedDate.getUTCDate() + days);
                    return adjustedDate;
                }

                return datesList.map(([startDateStr, endDateStr]: [string, string]) => {
                    let startDate = parseDate(startDateStr);
                    let endDate = parseDate(endDateStr);

                    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
                        throw new Error('Invalid date format. Please use YYYY-MM-DD.');
                    }

                    if (startDate > endDate) {
                        throw new Error('Start date cannot be after end date.');
                    }

                    let startDay = getDayOfWeek(startDate);
                    let endDay = getDayOfWeek(endDate);

                    if (startDay !== 1) {
                        const daysToAdjust = startDay === 0 ? 1 : -(startDay - 1);
                        startDate = adjustDate(startDate, daysToAdjust);
                    }

                    if (endDay !== 0) {
                        const daysToAdjust = 7 - endDay;
                        endDate = adjustDate(endDate, daysToAdjust);
                    }

                    return [formatDate(startDate), formatDate(endDate)];
                });
            }

            state.selectedPeriods = updateAndValidateDates(periods)
        },
    },
}
