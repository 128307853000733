export enum MetaScopeName {
    'signup' = 'signup',
    'login' = 'login',
    'credentialPage' = 'credential-page',
    'createAccount' = 'create-account',
    'faq' = 'faq',
    'dashboard' = 'dashboard',
    'invites' = 'invites',
    'logs' = 'logs',
    'notifications' = 'notifications',
    'paymentHistory' = 'payment-history',
    'paymentsSettings' = 'payments-settings',
    'streamers' = 'streamers',
    'subagencies' = 'subagencies',
    'streamer' = 'streamer',
    'settingsLanguage' = 'settings_language',
    'settingsNotifications' = 'settings_notifications',
    'settingsPasswords' = 'settings_passwords',
    'settingsPayment' = 'settings_payment',
    'settingsPersonalContacts' ='settings_personal-contacts'
}

export type MetaData = {
    scopeName: MetaScopeName,
    title: string,
    metaTags: any[]
}

export const liveBalanceMeta: Record<MetaScopeName, MetaData> = {
    'signup': {
        scopeName: MetaScopeName.signup,
        title: 'Get started with LiveBalance | Sign Up',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'LiveBalance is the leading platform for streaming performers. Learn how to start streaming and earning with our step-by-step registration process.' }
        ]
    },
    'login': {
        scopeName: MetaScopeName.login,
        title: 'Log into your LiveBalance account',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Log into your personal account to manage and analyze your data for scaling your agency.' }
        ]
    },
    'credential-page': {
        scopeName: MetaScopeName.credentialPage,
        title: 'Get your Personal Access to LiveBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Use your personal credentials to join the LiveBalance community. Congratulations on getting started and earning with us. Don\'t lose or share your login information with anyone.' }
        ]
    },
    'create-account': {
        scopeName: MetaScopeName.createAccount,
        title: 'Get started with LiveBalance | Sign Up',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'LiveBalance is the leading platform for streaming performers. Learn how to start streaming and earning with our step-by-step registration process.' }
        ]
    },
    'faq': {
        scopeName: MetaScopeName.faq,
        title: 'LiveBalance Guides: Help Getting Started With LiveBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'The FAQs include brand-new guides for setting up your agency. Please visit the LiveBalance Agency Guide to stay up-to-date on all the features of the platform.' }
        ]
    },
    'dashboard': {
        scopeName: MetaScopeName.dashboard,
        title: 'Discover Analytics Dashboard to dive deeper into statistics',
        metaTags: [
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Use real-time data to monitor your earnings and improve your performance. See and manage your agency in your LiveBalance Account.'
            }
        ]
    },
    'invites': {
        scopeName: MetaScopeName.invites,
        title: 'Invite Streamers and follow their activations Online',
        metaTags: [
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Monitor the ratio of attracted and activated streamers and the current status of account verification and moderation of content.'
            }
        ]
    },
    'logs': {
        scopeName: MetaScopeName.logs,
        title: 'Experience the benefits of Logs Tool in LiveBalance',
        metaTags: [
            {
                vmid: 'description',
                name: 'description',
                content:
                    "A handy tool for building your agency's reputation. Find out about violations, profile penalties, and bans of your streamers before it affects your agency."
            }
        ]
    },
    'notifications': {
        scopeName: MetaScopeName.notifications,
        title: 'Keep yourself updated with LiveBalance Notifications',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Check your daily notifications for better administration of your agency by being among the first to know about important updates.' }
        ]
    },
    'payment-history': {
        scopeName: MetaScopeName.paymentHistory,
        title: 'View Payment History through LiveBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Working periods, transactions, and payment statuses can be viewed under the Payment History page. Importing reports in appropriate formats is available.' }
        ]
    },
    'payments-settings': {
        scopeName: MetaScopeName.paymentsSettings,
        title: 'TODO_Manage your Personal Contacts | LiveBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'TODO_Use the best way to manage your agency - set up and easily edit personal contacts to interact with your team of streamers.' }
        ]
    },
    'streamers': {
        scopeName: MetaScopeName.streamers,
        title: 'Build your Streamer Relation Management tool',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Keep track of employee progress scales, view profiles, explore detailed information, use easy navigation and data table filters.' }
        ]
    },
    'subagencies': {
        scopeName: MetaScopeName.subagencies,
        title: 'Scale up your income by creating Sub-Agencies',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Get step-by-step instructions and the ability to create and manage sub-agencies. Build and expand your agency the way you want.' }
        ]
    },
    'streamer': {
        scopeName: MetaScopeName.streamer,
        title: 'View Streamer Profile for instant information',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Tired of waiting for your requests to be processed? Get access to streamer profiles with all previous recordings and the latest up-to-date information.' }
        ]
    },
    'settings_language': {
        scopeName: MetaScopeName.settingsLanguage,
        title: 'Check or change language settings | LiveBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Change the display language for your LiveBalance account to be able to use the tool for your greatest experience.' }
        ]
    },
    'settings_notifications': {
        scopeName: MetaScopeName.settingsNotifications,
        title: 'Change Notifications Settings in LiveBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'The LiveBalance platform helps you manage detailed revenue notifications, new streamer activations, fines, and violations in a few taps.' }
        ]
    },
    'settings_passwords': {
        scopeName: MetaScopeName.settingsPasswords,
        title: 'Change or reset your password | LiveBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'To change your email password, go to the Email and Password tab on the Settings page and fill in the fields below. Do not share your password with anyone, keep it secure.' }
        ]
    },
    'settings_payment': {
        scopeName: MetaScopeName.settingsPayment,
        title: 'Learn about LiveBalance Payment Methods',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Choose the appropriate payment method to get fast and secure payment for your Agency. Feel free to contact our 24/7 support team if you have any questions.' }
        ]
    },
    'settings_personal-contacts': {
        scopeName: MetaScopeName.settingsPersonalContacts,
        title: 'Manage your Personal Contacts | LiveBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Use the best way to manage your agency - set up and easily edit personal contacts to interact with your team of streamers.' }
        ]
    }
}

export const prideBalanceMeta = {
    'signup': {
        scopeName: MetaScopeName.signup,
        title: 'Start using PrideBalance now by creating an account and completing the sign-up process',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Join the best platform for streaming performers - PrideBalance. Follow our step-by-step registration process to learn how to start streaming and earning today.' }
        ]
    },
    'login': {
        scopeName: MetaScopeName.login,
        title: 'Sign in to your PrideBalance account',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Access your personal account to analyze and manage your data for scaling your agency.' }
        ]
    },
    'credential-page': {
        scopeName: MetaScopeName.credentialPage,
        title: 'Gain access to PrideBalance for your personal use and start managing your agency efficiently',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Obtain your Personal Access to the PrideBalance community. Remember to keep your login information secure and avoid sharing it with others.' }
        ]
    },
    'create-account': {
        scopeName: MetaScopeName.createAccount,
        title: 'Start using PrideBalance now by creating an account and completing the sign-up process',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Join the best platform for streaming performers - PrideBalance. Follow our step-by-step registration process to learn how to start streaming and earning today.' }
        ]
    },
    'faq': {
        scopeName: MetaScopeName.faq,
        title: 'PrideBalance Guides: Assistance for initiating PrideBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'The FAQs feature new manuals on establishing your agency. To keep informed about all platform capabilities, please refer to the PrideBalance Agency Guide.' }
        ]
    },
    'dashboard': {
        scopeName: MetaScopeName.dashboard,
        title: 'Explore the Analytics Dashboard to gain insights into various statistics',
        metaTags: [
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Use up-to-date information to track revenue and manage your agency through the analytics dashboard in your PrideBalance account.'
            }
        ]
    },
    'invites': {
        scopeName: MetaScopeName.invites,
        title: 'Send invitations to Streamers and track their activations Online',
        metaTags: [
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Observe the proportion of attracted and activated streamers, along with the present state of account verification and content moderation.'
            }
        ]
    },
    'logs': {
        scopeName: MetaScopeName.logs,
        title: 'Enjoy the advantages of the Logs Tool within PrideBalance',
        metaTags: [
            {
                vmid: 'description',
                name: 'description',
                content:
                    "A useful tool for enhancing your agency's reputation. Discover streamer violations, profile penalties, and bans before they impact your agency."
            }
        ]
    },
    'notifications': {
        scopeName: MetaScopeName.notifications,
        title: 'Stay informed with PrideBalance Notifications',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Peruse your daily notifications to enhance your agency management by being one of the first to learn about critical updates.' }
        ]
    },
    'payment-history': {
        scopeName: MetaScopeName.paymentHistory,
        title: 'Access Payment History via PrideBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'The Payment History page displays working periods, transactions, and payment statuses. It\'s possible to import reports in suitable formats.' }
        ]
    },
    'payments-settings': {
        scopeName: MetaScopeName.paymentsSettings,
        title: 'TODO_Manage your Personal Contacts | LiveBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'TODO_Use the best way to manage your agency - set up and easily edit personal contacts to interact with your team of streamers.' }
        ]
    },
    'streamers': {
        scopeName: MetaScopeName.streamers,
        title: 'Build your Streamer Interaction Management Dashboard',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Track the progress scales of your employees, and easily navigate and filter data tables to build your Streamer Interaction Management tool.' }
        ]
    },
    'subagencies': {
        scopeName: MetaScopeName.subagencies,
        title: 'Increase your earnings by establishing Sub-agencies',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Receive detailed guidance and the capability to establish and oversee sub-agencies. Construct and broaden your agency according to your preferences.' }
        ]
    },
    'streamer': {
        scopeName: MetaScopeName.streamer,
        title: 'View Streamer Profile real-time data',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Get instant access to streamer profiles with all their previous recordings and the latest up-to-date information, without waiting for your requests to be processed.' }
        ]
    },
    'settings_language': {
        scopeName: MetaScopeName.settingsLanguage,
        title: 'Review or adjust language settings with PrideBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Adjust the display language of your PrideBalance account to utilize the tool for the best experience.' }
        ]
    },
    'settings_notifications': {
        scopeName: MetaScopeName.settingsNotifications,
        title: 'Adjust Notification Settings in PrideBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'The PrideBalance platform streamlines revenue notifications, new streamer activations, fines, and violations management in just a few taps.' }
        ]
    },
    'settings_passwords': {
        scopeName: MetaScopeName.settingsPasswords,
        title: 'Modify or reset your password with PrideBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'To alter your email password, visit the Email and Password tab on the Settings page and complete the fields underneath. Keep your password secure and do not share it with anyone.' }
        ]
    },
    'settings_payment': {
        scopeName: MetaScopeName.settingsPayment,
        title: 'Familiarize yourself with PrideBalance Payment Methods',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Select the suitable payment method to obtain prompt and secure agency payments. If you have any questions, don\'t hesitate to contact our 24/7 support team.' }
        ]
    },
    'settings_personal-contacts': {
        scopeName: MetaScopeName.settingsPersonalContacts,
        title: 'Handle your Personal Contacts with PrideBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Optimize your agency management by setting up and effortlessly editing personal contacts to communicate with your streamer team.' }
        ]
    }
}

export const joolaMeta: Record<MetaScopeName, MetaData> = {
    'signup': {
        scopeName: MetaScopeName.signup,
        title: 'Begin Your joola.livebalance.biz Journey',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'joola.livebalance.biz stands as the foremost platform for streaming performers. Learn the step-by-step registration process to start streaming and earning.' }
        ]
    },
    'login': {
        scopeName: MetaScopeName.login,
        title: 'Access Your joola.livebalance.biz Account',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Sign in to your personal account to oversee and analyze your data, facilitating the growth of your agency.' }
        ]
    },
    'credential-page': {
        scopeName: MetaScopeName.credentialPage,
        title: 'Obtain Personal Access to joola.livebalance.biz',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Utilize your personal credentials to join the LiveBalance community. Congratulations on embarking on your journey to start earning with us. Safeguard your login information.' }
        ]
    },
    'create-account': {
        scopeName: MetaScopeName.createAccount,
        title: 'Begin Your joola.livebalance.biz Journey',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'joola.livebalance.biz stands as the foremost platform for streaming performers. Learn the step-by-step registration process to start streaming and earning.' }
        ]
    },
    'faq': {
        scopeName: MetaScopeName.faq,
        title: 'joola.livebalance.biz Guides: Getting Started Assistance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Discover comprehensive guides for setting up your agency in the FAQs section. Stay informed about all joola.livebalance.biz features through the Agency Guide.' }
        ]
    },
    'dashboard': {
        scopeName: MetaScopeName.dashboard,
        title: 'Explore Analytics Dashboard for Insights',
        metaTags: [
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Utilize real-time data for tracking your earnings and enhancing your performance. Navigate and manage your agency effectively through your joola.livebalance.biz Account.'
            }
        ]
    },
    'invites': {
        scopeName: MetaScopeName.invites,
        title: 'Invite Streamers and Monitor Activations Online',
        metaTags: [
            {
                vmid: 'description',
                name: 'description',
                content:
                    'Track the ratio of attracted and activated streamers. Stay updated on account verification and content moderation statuses.'
            }
        ]
    },
    'logs': {
        scopeName: MetaScopeName.logs,
        title: 'Leverage Logs Tool for Reputation Management',
        metaTags: [
            {
                vmid: 'description',
                name: 'description',
                content:
                    "A valuable tool for building your agency's reputation. Learn about violations, profile penalties, and streamer bans proactively."
            }
        ]
    },
    'notifications': {
        scopeName: MetaScopeName.notifications,
        title: 'Stay Updated with joola.livebalance.biz Notifications',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Regularly check notifications for effective agency administration. Be among the first to receive important updates and announcements.' }
        ]
    },
    'payment-history': {
        scopeName: MetaScopeName.paymentHistory,
        title: 'Review Payment History on joola.livebalance.biz',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'View working periods, transactions, and payment statuses conveniently on the Payment History page. Import reports in various formats as needed.' }
        ]
    },
    'payments-settings': {
        scopeName: MetaScopeName.paymentsSettings,
        title: 'TODO_Manage your Personal Contacts | LiveBalance',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'TODO_Use the best way to manage your agency - set up and easily edit personal contacts to interact with your team of streamers.' }
        ]
    },
    'streamers': {
        scopeName: MetaScopeName.streamers,
        title: 'Construct Your Streamer Relationship Tool',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Monitor employee progress scales, view profiles, and explore detailed information. Utilize easy navigation and data table filters for a seamless experience.' }
        ]
    },
    'subagencies': {
        scopeName: MetaScopeName.subagencies,
        title: 'Boost Income with Sub-Agencies',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Follow step-by-step instructions to create and manage sub-agencies. Customize and expand your agency according to your preferences.' }
        ]
    },
    'streamer': {
        scopeName: MetaScopeName.streamer,
        title: 'View Streamer Profiles for Quick Insights',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'No more waiting for your requests to be processed. Access streamer profiles with previous recordings and the latest information instantly.' }
        ]
    },
    'settings_language': {
        scopeName: MetaScopeName.settingsLanguage,
        title: 'Adjust or Change Language Settings',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Enhance your joola.livebalance.biz experience by changing the display language. Tailor the tool to your preferences for the ultimate user experience.' }
        ]
    },
    'settings_notifications': {
        scopeName: MetaScopeName.settingsNotifications,
        title: 'Customize Notifications Settings',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Manage detailed revenue notifications, new streamer activations, fines, and violations efficiently through joola.livebalance.biz platform settings.' }
        ]
    },
    'settings_passwords': {
        scopeName: MetaScopeName.settingsPasswords,
        title: 'Change or Reset Your Password Securely',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Ensure the security of your account by changing or resetting your email password. Navigate to the Email and Password tab on the Settings page. Keep your password confidential.' }
        ]
    },
    'settings_payment': {
        scopeName: MetaScopeName.settingsPayment,
        title: 'Learn about joola.livebalance.biz Payment Methods',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Familiarize yourself with the payment methods available on joola.livebalance.biz. Choose the most suitable method for secure and prompt agency payments. Contact our 24/7 support team for assistance.' }
        ]
    },
    'settings_personal-contacts': {
        scopeName: MetaScopeName.settingsPersonalContacts,
        title: 'Manage Personal Contacts for Effective Interaction',
        metaTags: [
            { vmid: 'description', name: 'description', content: 'Optimize your agency management by setting up and easily editing personal contacts. Enhance communication with your team of streamers.' }
        ]
    }
}
