import { computed, ComputedRef } from 'vue'
import { useStore } from 'vuex'
import { formatDate, getWeekRange } from '@/helpers/date'
import {
    InviteItem,
    MediaType,
    StreamerInfo,
    DayActivity,
    ActivityStatistic,
    ActivityStatisticByDays
} from '@/interfaces/streamer'
import { Status, DateRange } from '@/interfaces/data'
import { StreamersActivities, PeriodActivity } from '@/interfaces/streamer'
import { sumObjects } from '@/helpers/data'

type PeriodActivitiesByDays = { [periodId: string]: DayActivity[] }

export interface StreamerComposable {
    activityPeriods: ComputedRef<DateRange[]>
    getCurrentInviteMediaNumber(
        invite: InviteItem,
        mediaType: MediaType
    ): number
    getInviteMediaStatus(invite: InviteItem, mediaType: MediaType): Status
    getInviteMediaVisibility(invite: InviteItem, mediaType: MediaType): boolean
    sumStreamerActivities(activities: DayActivity[], isMultiplePeriodsSelected: boolean, profile: any): ActivityStatistic
    getComputedStreamers(): ComputedRef<StreamerInfo[]>
    getAllProfiles(): any,
    getStreamersActivitiesByDays(
        streamers: StreamerInfo[],
        filterCb?: (streamer: StreamerInfo) => boolean
    ): ActivityStatisticByDays
    countActiveStreamers(streamers: StreamerInfo[]): number
}

export function useStreamer(): StreamerComposable {
    const store = useStore()

    const invites = computed<InviteItem[]>(() => store.getters.invites)
    const profiles = computed<any>(() => store.getters.profiles)
    const allProfiles = computed<any>(() => store.getters.allProfiles)
    const activeProfiles = computed<any>(() => store.getters.activeProfiles)
    const streamersActivities = computed<StreamersActivities>(
        () => store.getters.streamersActivities
    )
    const selectedPeriods = computed<DateRange[]>(
        () => store.getters.selectedPeriods
    )

    const firstActivityDate = computed<string>(() => {
        return Object.values(streamersActivities.value).reduce(
            (acc: string, item) => {
                for (const dayActivity of item.days) {
                    acc =
                        dayActivity.date < acc || !acc ? dayActivity.date : acc
                }

                return acc
            },
            ''
        )
    })

    function generateWeeklyDatesUntilNow(startDateString: string): string[][] {
        const startDate = new Date(startDateString);
        const currentDate = new Date();

        const weeklyDates: string[][] = [];

        const formatDate = (date: Date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2,'0');
            const day = String(date.getDate()).padStart(2,'0');
            return `${year}-${month}-${day}`;
        }

        let weekStartDate = new Date(startDate);
        const dayOfWeek = weekStartDate.getDay();
        const daysToMonday = (dayOfWeek + 6) % 7;
        weekStartDate.setDate(weekStartDate.getDate() - daysToMonday);

        while (weekStartDate <= currentDate) {
            const weekEndDate = new Date(weekStartDate);
            weekEndDate.setDate(weekStartDate.getDate() + 6);

            weeklyDates.push([
                formatDate(weekStartDate),
                formatDate(weekEndDate),
            ]);

            weekStartDate.setDate(weekStartDate.getDate() + 7);
        }

        return weeklyDates.reverse();
    }

    // @ts-ignore
    const activityPeriods = computed<DateRange[]>(() => {
        const dateOfCreation = store.state.profileStore.agencyInfo.created;

        const startDateString = dateOfCreation
            ? new Date(dateOfCreation).toISOString().split('T')[0]
            : '2024-03-18';

        return generateWeeklyDatesUntilNow(startDateString);
    });

    function getCurrentInviteMediaNumber(
        invite: InviteItem,
        mediaType: MediaType
    ): number {
        return invite.amIReady.current[mediaType]
    }

    function getInviteMediaVisibility(
        invite: InviteItem,
        mediaType: MediaType
    ): boolean {
        return invite.amIReady.target[mediaType] !== 0
    }

    function getInviteMediaStatus(
        invite: InviteItem,
        mediaType: MediaType
    ): Status {
        const targetNumber = invite.amIReady.target[mediaType]
        const currentNumber = invite.amIReady.current[mediaType]

        if (currentNumber < targetNumber) {
            return Status.Banned
        }

        let unapprovedMediaContentCount = 0

        if (mediaType === 'avatar') {
            if (!invite.avatar && invite.avatarOnModeration) {
                unapprovedMediaContentCount = 1
            }
        } else {
            const mediaContent =
                mediaType === 'gesture' ? invite.gestures : invite[mediaType]

            unapprovedMediaContentCount = (mediaContent as Array<any>)?.filter(
                (mediaItem) =>
                    mediaItem.status !== 'approved' &&
                    mediaItem.moderationStatus !== 'approved'
            ).length
        }

        if (currentNumber - unapprovedMediaContentCount < targetNumber) {
            return Status.Pending
        }

        return Status.Active
    }

    function sumPeriodActivities(
        periodActivities: DayActivity[],
        profile: any
    ): ActivityStatistic {
        if (!periodActivities.length) return {}

        const accumulableFields: Array<keyof ActivityStatistic> = [
            'lastPeriodTotal',
            'activeStreamers'
        ]

        // we need to select LAST appeared value by this fields(not max by period!).
        // For example streamer received 20+ k in first day, the next day he already have streamersIncome (and other connected values)
        // but if during next days before payment(till next week beginning) streamer received some penalty points, but no income
        // her streamersIncome decreased and wee need to select this value, not previous(what was bigger)
        // also possible that in the middle of the week followed fields will be filled,
        // but on the next day there will be penalty and there will not be values in this fields. But still we need to take this fields from the last day.
        const lastDayAppearedFields: Array<keyof ActivityStatistic> = [
            'thisPeriod',
            'streamersIncome',
            'redeemablePoints',
            'agencyCommission',
            'agencyTotalRevenue',
            'profileLevel',
            'profileExchangeRate'
        ]

        const sortedActivities = periodActivities.sort((a, b) => {
            if (a.date < b.date) {
                return -1
            } else if (a.date > b.date) {
                return 1
            } else {
                return 0
            }
        })

        const sumPeriodActivities = sortedActivities.reduce(
            (acc: ActivityStatistic, dayActivity) => {
                Object.entries(dayActivity).forEach(([fieldName, value]) => {
                    if (typeof value !== 'number') {
                        return
                    }

                    if (
                        accumulableFields.includes(
                            fieldName as keyof ActivityStatistic
                        )
                    ) {
                        acc[fieldName as keyof ActivityStatistic] = Math.max(
                            value,
                            acc[fieldName as keyof ActivityStatistic] ?? 0
                        )
                        return
                    }

                    if (
                        lastDayAppearedFields.includes(
                            fieldName as keyof ActivityStatistic
                        )
                    ) {
                        return
                    }

                    acc[fieldName as keyof ActivityStatistic] =
                        value + (acc[fieldName as keyof ActivityStatistic] ?? 0)
                })

                return acc
            },
            {}
        )

        // read lastDayAppearedFields logic described above
        lastDayAppearedFields.forEach((fieldName: keyof ActivityStatistic) => {
            if (fieldName === 'profileLevel') {
                sumPeriodActivities[fieldName as keyof ActivityStatistic] =
                    sortedActivities[sortedActivities.length - 1][
                        fieldName as keyof ActivityStatistic
                        ] || profile.level || 0
            } else if (fieldName === 'profileExchangeRate') {
                sumPeriodActivities[fieldName as keyof ActivityStatistic] =
                    sortedActivities[sortedActivities.length - 1][
                        fieldName as keyof ActivityStatistic
                        ] || profile.exchangeRate || 0
            } else {
                sumPeriodActivities[fieldName as keyof ActivityStatistic] =
                    sortedActivities[sortedActivities.length - 1][
                        fieldName as keyof ActivityStatistic
                        ] ?? 0
            }
        })

        return sumPeriodActivities
    }

    function sortByPeriodId(periodsActivities: PeriodActivity[]) {
        periodsActivities.sort((a, b) => {
            const [aYear, aWeek] = a.periodId
                .split('_')
                .map((item) => Number(item))
            const [bYear, bWeek] = b.periodId
                .split('_')
                .map((item) => Number(item))

            const yearDiff = aYear - bYear
            const weekDiff = aWeek - bWeek

            return yearDiff || weekDiff
        })
    }

    // find all previous periods ids since last redeemablePoints been paid
    // for example if we selected 2024_23-2024_25 weeks
    // for 2024_23 accumulatedPeriods be empty
    // for 2024_24 accumulatedPeriods be 2024_23
    // for 2024_25 accumulatedPeriods be 2024_23 and 2024_24
    function setAccumulatedPeriods(
        periodsActivities: PeriodActivity[]
    ): PeriodActivity[] {
        sortByPeriodId(periodsActivities)

        let currentAccumulatedPeriods: string[] = []

        return periodsActivities.map((periodActivity) => {
            periodActivity.accumulatedPeriods = [...currentAccumulatedPeriods]

            if (periodActivity.redeemablePoints) {
                currentAccumulatedPeriods = []
            } else {
                currentAccumulatedPeriods.push(periodActivity.periodId)
            }

            return periodActivity
        })
    }

    function sumTotalActivities(
        periodsActivities: PeriodActivity[],
        isMultiplePeriodsSelected: boolean
    ): ActivityStatistic {
        const accumulableFields: Array<keyof ActivityStatistic> = [
            'lastPeriodTotal',
            'thisPeriod',
            'coinsTotal'
        ]

        const displayedJustForSinglePeriodFields: Array<keyof ActivityStatistic> = [
            'profileLevel',
            'profileExchangeRate'
        ]

        // all periods that considered as accumulable periods(where values are temporary and should be ignored,
        // just last obe should de considered. last ones is the periods that is not in this list)
        const totalAccumulatedPeriods: string[] = periodsActivities.reduce(
            (acc: string[], periodActivity) => {
                periodActivity.accumulatedPeriods?.forEach((periodId) => {
                    if (!acc.includes(periodId)) {
                        acc.push(periodId)
                    }
                })

                return acc
            },
            []
        )

        return periodsActivities.reduce(
            (acc: ActivityStatistic, periodActivity) => {
                Object.entries(periodActivity).forEach(([fieldName, value]) => {
                    if (typeof value !== 'number') {
                        return
                    }

                    if (
                        totalAccumulatedPeriods.includes(
                            periodActivity.periodId
                        ) &&
                        accumulableFields.includes(
                            fieldName as keyof ActivityStatistic
                        )
                    ) {
                        return
                    }

                    if (
                        isMultiplePeriodsSelected && displayedJustForSinglePeriodFields.includes(
                            fieldName as keyof ActivityStatistic
                        )
                    ) {
                        acc[fieldName as keyof ActivityStatistic] = -1
                        return
                    }

                    acc[fieldName as keyof ActivityStatistic] =
                        value + (acc[fieldName as keyof ActivityStatistic] ?? 0)
                })

                return acc
            },
            {}
        )
    }

    function sumStreamerActivities(
        activities: DayActivity[],
        isMultiplePeriodsSelected: boolean,
        profile: any
    ): ActivityStatistic {
        const periodsActivitiesByDays: PeriodActivitiesByDays =
            activities.reduce((acc: PeriodActivitiesByDays, dayActivity) => {
                if (acc[dayActivity.periodId]) {
                    acc[dayActivity.periodId].push(dayActivity)
                } else {
                    acc[dayActivity.periodId] = [dayActivity]
                }

                return acc
            }, {})

        let periodsActivities: PeriodActivity[] = Object.entries(
            periodsActivitiesByDays
        ).reduce((acc: PeriodActivity[], [periodId, periodActivities]) => {
            acc.push({
                periodId,
                ...sumPeriodActivities(periodActivities, profile)
            })

            return acc
        }, [])

        periodsActivities = setAccumulatedPeriods(periodsActivities)

        const summedStreamersActivities = sumTotalActivities(periodsActivities, isMultiplePeriodsSelected)

        summedStreamersActivities.activeStreamers =
            summedStreamersActivities.activeStreamers ? 1 : 0

        return summedStreamersActivities
    }

    function getComputedStreamers() {
        return computed<any>(() => {
            return Object.values(streamersActivities.value).map((profile: any) => {
                const isMultiplePeriodsSelected = selectedPeriods.value.length > 1
                const activities =
                    profile?.days.filter(
                        // @ts-ignore
                    (dayActivity) => {
                        return selectedPeriods.value.some(
                          (period: DateRange) =>
                            dayActivity.date >= period[0] &&
                            dayActivity.date <= period[1]
                        )
                    }
                  ) || []

                return {
                    ...profile,
                    activities,
                    totalActivities: sumStreamerActivities(activities, isMultiplePeriodsSelected, profile),
                    summary: profile?.summary || {},
                }
            })
        })
    }

    function getAllProfiles() {
        return computed<any>(() => {
            return profiles.value
        })
    }

    function getStreamersActivitiesByDays(
        streamers: StreamerInfo[],
        filterCb?: (streamer: StreamerInfo) => boolean
    ): ActivityStatisticByDays {
        if (filterCb) {
            streamers = streamers.filter(filterCb)
        }

        return streamers.reduce((acc: ActivityStatisticByDays, streamer) => {
            streamer.activities.forEach((dayActivity) => {
                acc[dayActivity.date] = sumObjects([
                    acc[dayActivity.date] || {},
                    dayActivity
                ])
            })

            return acc
        }, {})
    }

    function countActiveStreamers(streamers: StreamerInfo[]): number {
        return streamers.length

    }

    return {
        activityPeriods,
        getCurrentInviteMediaNumber,
        getInviteMediaVisibility,
        getInviteMediaStatus,
        sumStreamerActivities,
        getComputedStreamers,
        getAllProfiles,
        getStreamersActivitiesByDays,
        countActiveStreamers
    }
}
