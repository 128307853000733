let scriptLoaderWorker

export function loadScriptByWorker(url) {
    if (!scriptLoaderWorker) {
        scriptLoaderWorker = new Worker('/js/scriptLoaderWorker.js')
    }

    scriptLoaderWorker.onmessage = e => {
        eval(e.data)
    }

    scriptLoaderWorker.postMessage(url)
}

export function loadScriptDefault(url) {
    const script = document.createElement('script')
    script.async = true
    script.src = url

    document.head.appendChild(script)
}