import { Module } from 'vuex'
import { RootState } from '@/store'
import { getGeoInfo } from '@/helpers/geo'

export interface DeviceState {
    windowWidth: number,
    trackedCountry: string,
}

export const deviceStore: Module<DeviceState, RootState> = {
    state: {
        windowWidth: window.innerWidth,
        trackedCountry: sessionStorage.trackedCountry || '',
    },
    getters: {
        windowWidth: state => state.windowWidth,
        trackedCountry: state => state.trackedCountry,
    },
    mutations: {
        setWindowWidth(state) {
			state.windowWidth = window.innerWidth
		},
        setTrackedCountry(state, country) {
            state.trackedCountry = country
            sessionStorage.trackedCountry = country
        },
    },
    actions: {
        async trackCountry({ commit }) {
            await getGeoInfo()
                .then(geoInfo => {
                    commit('setTrackedCountry', geoInfo.loc || 'US')
                })
                .catch(() => {
                    commit('setTrackedCountry', 'US')
                })
        }
    }
}
