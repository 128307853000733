import { inject } from 'vue'
import { LocalizationsMap, Localization } from '@/plugins/localization'

type LocalizationScope = 'global' | 'faq'

export function useLocalization(scope: LocalizationScope = 'global') {
    const localization: Localization = inject<LocalizationsMap>('localization')?.[scope]!

    if (!localization) {
        console.error(`Unexpected scope: ${scope}`)
    }

    const replaceVarsWithValues = function(text: string, vars: Record<string, string | number | string[]> = {}) {
        for (const [key, value] of Object.entries(vars)) {
            text = text.replace(`[${key}]`, value.toString())
        }

        return text
    }

    return {
        locale: localization?.locale,
        switchLocale: (locale: string) => localization?.switchLocale(locale),
        t: (key: string, vars: Record<string, string | number | string[]> = {}) => {
            let translatedText = localization?.translate(key).value || key

            return replaceVarsWithValues(translatedText, vars)
        },
        tc: (key: string, choice: number, vars: Record<string, string | number | string[]> = {}) => {
            let translatedText = localization?.translatePlural(key, choice).value || key

            // enable using passed value as localization param
            vars['n'] = choice

            return replaceVarsWithValues(translatedText, vars)
        }
    }
}