import { ref } from 'vue'

const isAppLoading = ref<boolean>(false)

export function useAppLoading() {
    function setAppLoading(value: boolean) {
        isAppLoading.value = value
    }

    return {
        isAppLoading,
        setAppLoading
    }
}
