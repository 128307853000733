import { defineAsyncComponent } from 'vue'

type VisibilityItemName = keyof typeof VisibilityNames

interface VisibilityItemConstructor {
    name: VisibilityItemName
    componentPath?: string
    useStorage?: boolean
    onInit?: () => any
    onRemove?: () => void
}

export class VisibilityItem {
    data: any = null
    componentPath: string = ''
    storageValue: any = null
    name: VisibilityItemName
    useStorage: boolean = false

    constructor({ name, componentPath = '', useStorage = false, onInit, onRemove }: VisibilityItemConstructor) {
        this.name = name
        this.componentPath = componentPath
        this.useStorage = useStorage

        if (useStorage) {
            this.storageValue = JSON.parse(localStorage.getItem(name) || '') || null
        }

        if (onInit) {
            this.onInit = onInit
        }

        if (onRemove) {
            this.onRemove = onRemove
        }
    }

    clearStorage() {
        localStorage.removeItem(this.name)
        this.storageValue = null
    }

    setToStorage(value: any) {
        if (this.useStorage) {
            localStorage.setItem(this.name, JSON.stringify(value))
            this.storageValue = value
        }
    }

    setData(data: any) {
        this.data = data
    }

    onInit(): any {
        return this.storageValue
    }

    onRemove(): void {
        this.clearStorage()
    }
}

export enum VisibilityNames {
    'contactsModal' = 'contactsModal',
    // 'sidePayoneerModal' = 'sidePayoneerModal',
    'sidePaxumModal' = 'sidePaxumModal',
    'sideBinanceModal' = 'sideBinanceModal',
    'sideAdvCashModal' = 'sideAdvCashModal',
    'successModal' = 'successModal',
    'videoModal' = 'videoModal',
    'photoModal' = 'photoModal',
    'sidePenaltiesHistoryModal' = 'sidePenaltiesHistoryModal',
    'sideWarningsHistoryModal' = 'sideWarningsHistoryModal',
    'sideBansHistoryModal' = 'sideBansHistoryModal',
    'banModal' = 'banModal',
    'logoutModal' = 'logoutModal',
    'mobileSidebar' = 'mobileSidebar',
    'bottomFiltersModal' = 'bottomFiltersModal',
    'bottomSortableModal' = 'bottomSortableModal',
    'bottomDashboardFilterModal' = 'bottomDashboardFilterModal',
    'bottomInvitesFilter' = 'bottomInvitesFilter',
    'bottomInviteStreamersModal' = 'bottomInviteStreamersModal',
    'termsAndConditionsModal' = 'termsAndConditionsModal',
    'termsAndConditionsPrideModal' = 'termsAndConditionsPrideModal',
    'privacyPolicyModal' = 'privacyPolicyModal',
    'privacyPolicyPrideModal' = 'privacyPolicyPrideModal',
    'contractorPolicyModal' = 'contractorPolicyModal',
    'contractorPolicyPrideModal' = 'contractorPolicyPrideModal',
    'sideEditPaymentTargetModal' = 'sideEditPaymentTargetModal',
    'sideStreamerLevelInfoModal' = 'sideStreamerLevelInfoModal',
    'authIssue' = 'authIssue'
}

const visibilityItemsList: VisibilityItem[] = [
    { name: VisibilityNames.contactsModal, componentPath: '/modals/ContactsModal.vue' },
    // { name: VisibilityNames.sidePayoneerModal, componentPath: '/modals/SidePayoneerModal.vue' },
    { name: VisibilityNames.sidePaxumModal, componentPath: '/modals/SidePaxumModal.vue' },
    { name: VisibilityNames.sideBinanceModal, componentPath: '/modals/SideBinanceModal.vue' },
    { name: VisibilityNames.sideAdvCashModal, componentPath: '/modals/SideAdvCashModal.vue' },
    { name: VisibilityNames.successModal, componentPath: '/modals/SuccessModal.vue' },
    { name: VisibilityNames.videoModal, componentPath: '/modals/VideoModal.vue' },
    { name: VisibilityNames.photoModal, componentPath: '/modals/PhotoModal.vue' },
    { name: VisibilityNames.sidePenaltiesHistoryModal, componentPath: '/modals/SidePenaltiesHistoryModal.vue' },
    { name: VisibilityNames.sideWarningsHistoryModal, componentPath: '/modals/SideWarningsHistoryModal.vue' },
    { name: VisibilityNames.sideBansHistoryModal, componentPath: '/modals/SideBansHistoryModal.vue' },
    { name: VisibilityNames.banModal, componentPath: '/modals/BanModal.vue' },
    { name: VisibilityNames.logoutModal, componentPath: '/modals/LogoutModal.vue' },
    { name: VisibilityNames.mobileSidebar },
    { name: VisibilityNames.bottomFiltersModal, componentPath: '/modals/BottomFiltersModal.vue' },
    { name: VisibilityNames.bottomSortableModal, componentPath: '/modals/BottomSortableModal.vue' },
    { name: VisibilityNames.bottomDashboardFilterModal, componentPath: '/modals/BottomDashboardFilterModal.vue' },
    { name: VisibilityNames.bottomInviteStreamersModal, componentPath: '/modals/BottomInviteStreamersModal.vue' },
    { name: VisibilityNames.termsAndConditionsModal, componentPath: '/modals/TermsAndConditionsModal.vue' },
    { name: VisibilityNames.termsAndConditionsPrideModal, componentPath: '/modals/TermsAndConditionsPrideModal.vue' },
    { name: VisibilityNames.privacyPolicyModal, componentPath: '/modals/PrivacyPolicyModal.vue' },
    { name: VisibilityNames.privacyPolicyPrideModal, componentPath: '/modals/PrivacyPolicyPrideModal.vue' },
    { name: VisibilityNames.contractorPolicyModal, componentPath: '/modals/ContractorPolicyModal.vue' },
    { name: VisibilityNames.contractorPolicyPrideModal, componentPath: '/modals/ContractorPolicyPrideModal.vue' },
    { name: VisibilityNames.sideEditPaymentTargetModal, componentPath: '/modals/SideEditPaymentTargetModal.vue' },
    { name: VisibilityNames.sideStreamerLevelInfoModal, componentPath: '/modals/SideStreamerLevelInfoModal.vue' },
    { name: VisibilityNames.authIssue, componentPath: '/modals/AuthIssue.vue' },
].map(visibleItemOptions => new VisibilityItem(visibleItemOptions))

const visibilityItems: { [key: string]: any } = {}
const visiblePool: { [key: string]: any } = {}
const globalVisibleComponents = visibilityItemsList.filter(visibleItem => visibleItem.componentPath)
    .reduce((acc: any, visibleComponent) => {
        acc[visibleComponent.name] = defineAsyncComponent(() => import(`@/components${visibleComponent.componentPath}`))
        return acc
    }, {})

visibilityItemsList.forEach(item => {
    visibilityItems[item.name] = item

    if (item.onInit()) {
        visiblePool[item.name] = item
    }
})

export default {
    visibilityItems,
    visiblePool,
    globalVisibleComponents,
}