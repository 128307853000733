import { Module } from 'vuex'
import { UAParser } from 'ua-parser-js'

import { RootState } from '@/store'
import { User, RolesGroup } from '@/interfaces/user'
import { PackageName } from '@/interfaces/brands'
import { user } from '@/helpers/user'
import { axiosCore, axiosAdminBack, setAuthToken } from '@/plugins/axios'
import { isMobileDevice, isAppleMobileDevice, DeviceOs } from '@/helpers/device'

import {
    CheckUidResponse,
    SignupData,
    SignupResponse,
    SignupStorageData,
    SignupAgency,
    AuthBody
} from '@/interfaces/auth'

import {
    storageJsonParser,
    generateEmail,
    generateRandomPassword
} from '@/helpers/general'

import { copyDeepSimple } from '@/helpers/data'

import { LocaleError } from '@/helpers/error'

import {
    currentBrand,
    isLiveBalance,
    isPrideBalance,
    isJoolaBalance
} from '@/helpers/brands'

export interface AuthState {
    user: User
    support?: User
    deviceId: string
    signupResponse: SignupResponse
    signupStorageData: SignupStorageData
    signupAgency: SignupAgency
}

export interface UpdatePasswordData {
    password: string
    token?: string
}

export const authStore: Module<AuthState, RootState> = {
    state: {
        user: user,
        support: storageJsonParser('support') || null,
        deviceId: '',
        signupResponse: storageJsonParser('signupResponse') || null,
        signupStorageData: storageJsonParser('signupStorageData') || null,
        signupAgency: storageJsonParser('signupAgency')
    },
    getters: {
        user: (state) => state.user,
        support: (state) => state.support,
        profile: (state) => state.user?.profile,
        authToken: (state) => state.user?.authData?.token,
        isAuth: (state) => Boolean(state.user?.authData?.token),
        deviceId: (state) => state.deviceId,
        signupResponse: (state) => state.signupResponse,
        signupStorageData: (state) => state.signupStorageData,
        signupAgency: (state) => state.signupAgency
    },
    mutations: {
        setUser(state, user = {}) {
            state.user = user
            localStorage.setItem('user', JSON.stringify(user))
        },
        setSupport(state, support = {}) {
            state.support = support
            localStorage.setItem('support', JSON.stringify(support))
        },
        updateProfile(state, data) {
            const userFromLocalStorage = storageJsonParser('user')

            userFromLocalStorage.profile = copyDeepSimple(data)
            localStorage.setItem('user', JSON.stringify(userFromLocalStorage))
            state.user.profile = copyDeepSimple(data)
        },
        generateDeviceId(state) {
            const deviceId = `adminka_${Date.now()}`
            state.deviceId = deviceId
            localStorage.setItem('deviceId', deviceId)
        },
        setSignupResponse(state, data: SignupResponse) {
            state.signupResponse = data
            localStorage.setItem('signupResponse', JSON.stringify(data))
        },
        setSignupStorageData(state, data: SignupStorageData) {
            state.signupStorageData = data
            localStorage.setItem('signupStorageData', JSON.stringify(data))
        },
        setSignupAgency(state, agency: SignupAgency) {
            state.signupAgency = agency
            localStorage.setItem('signupAgency', JSON.stringify(agency))
        }
    },
    actions: {
        async checkIsAgencyRegistered(ctx, email) {
            return await axiosAdminBack.post('/v2/isAgencyRegistered', {
                email
            })
        },

        async checkIsRegistered(ctx, email) {
            let packageName = currentBrand.packageName

            if (!isPrideBalance) {
                if (isMobileDevice) {
                    if (isAppleMobileDevice) {
                        packageName = PackageName.TOPLIVE
                    } else {
                        packageName = PackageName.OLIVE
                    }
                } else {
                    packageName = PackageName.JOOLA
                }
            }

            return await axiosCore
                .post<any, { isRegistered: boolean }>(
                    '/v2/isRegistered',
                    { email },
                    {
                        headers: {
                            packagename: packageName
                        }
                    }
                )
                .then((data) => data?.isRegistered)
        },

        async authFromAdminPanel({ commit, getters }, { authToken, agencyId }) {
            return await axiosCore
                .get('/profile', {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'as-agency-id': agencyId
                    }
                })
                .then(async (data) => {
                    commit('setUser', {
                        authData: { token: authToken },
                        profile: data
                    })

                    if (!getters.support) {
                        setAuthToken(authToken)
                    }

                    return data
                })
        },

        async checkIsRegisteredAndGetPackageName(
            { dispatch },
            email: string
        ): Promise<string> {
            if (isLiveBalance) {
                const { isRegistered, brandId, isAgency } = await dispatch(
                    'checkIsAgencyRegistered',
                    email
                )

                if (!(isRegistered && isAgency))
                    throw new Error('email_not_registered')

                if (brandId === 6) {
                    return 'com.callmechat'
                }
            }

            if (isPrideBalance || isJoolaBalance) {
                const isRegistered = await dispatch('checkIsRegistered', email)

                if (!isRegistered) throw new Error('email_not_registered')

                return currentBrand.packageName
            }

            return ''
        },

        async authorize({ dispatch, commit, getters }, data) {
            try {
                if (!data?.email || !data?.password)
                    throw new Error('empty_email_and_password')

                if (!getters.deviceId) commit('generateDeviceId')

                const deviceId = getters.deviceId

                const body: AuthBody = {
                    email: data.email,
                    password: data.password,
                    deviceId,
                    method: 'ownemail',
                    token: data.token
                }

                const packageName = await dispatch(
                    'checkIsRegisteredAndGetPackageName',
                    data.email
                )

                if (packageName) {
                    body.packageName = packageName
                }

                const user = await axiosCore.post<any, User>('/login', body)

                if (user.profile.rolesGroup !== RolesGroup.agency) {
                    throw new Error('email_not_registered')
                }

                commit('setUser', user)
                //@ts-ignore
                setAuthToken(user.authData.token)
                await dispatch('loadOnboardingScreens')
                return user
            } catch (err) {
                console.warn(err)
                //@ts-ignore
                const statusCode = err?.response?.status
                if (statusCode === 404) {
                    //@ts-ignore
                    const responseErrorStatus = err?.response?.data.status
                    //@ts-ignore
                    if (responseErrorStatus === 464)
                        throw new Error('wrong_email_or_password')
                    if (responseErrorStatus === 465)
                        throw new Error('Registration rate limit')
                    if (responseErrorStatus === 483)
                        location.href = `${location.origin}?banned=true`
                }

                //@ts-ignore
                throw new Error(err?.message)
            }
        },

        async updatePassword({ state }, data: UpdatePasswordData) {
            await axiosCore.post(
                '/updatePassword',
                { password: data.password },
                {
                    headers: {
                        Authorization: `Bearer ${
                            data.token || state.user.authData.token
                        }`
                    }
                }
            )
        },

        async signup({ commit, dispatch }, data: SignupData) {
            const isRegistered = await dispatch('checkIsRegistered', data.email)

            if (isRegistered) {
                throw new LocaleError(
                    'This email has already been used!',
                    'errors.email_already_used'
                )
            }

            if (currentBrand.packageName) {
                data.packageName = currentBrand.packageName
            }

            let parser = new UAParser(window.navigator.userAgent)
            let deviceModel = parser.getResult()

            if (deviceModel?.device) {
                const { model, vendor } = deviceModel.device
                if (model !== undefined || vendor !== undefined) {
                    //@ts-ignore
                    data = { ...data, device: { model, vendor } }
                }
            }

            if (!isPrideBalance) {
                if (isMobileDevice) {
                    if (isAppleMobileDevice) {
                        data.packageName = PackageName.TOPLIVE
                        data.os = DeviceOs.IOS
                    } else {
                        data.packageName = PackageName.OLIVE
                        data.fallbackPackageName = PackageName.JOOLA
                        data.os = DeviceOs.Android
                    }
                } else {
                    data.packageName = PackageName.JOOLA
                    data.os = DeviceOs.WEB
                }
            }

            return await axiosCore
                .post<any, SignupResponse>('/interim/signup', data)
                .then((res) => {
                    if (res !== undefined) {
                        if (res?.oneLink) {
                            const isGoogle = res.oneLink.url.includes('google')
                            if (isGoogle) {
                                return { isGoogleRedirect: true}
                            }
                        }
                    }

                    commit('setSignupStorageData', {
                        email: data.email,
                        password: data.password
                    })

                    commit('setSignupResponse', res)

                    return res
                })
        },

        async generateEmailAndPassword({ dispatch }) {
            let email, isRegistered

            do {
                email = generateEmail()
                isRegistered = await dispatch('checkIsRegistered', email)
            } while (isRegistered)

            const password = generateRandomPassword()

            return {
                email,
                password
            }
        },

        async checkUid({ commit }, uid: string) {
            return await axiosCore
                .post<any, CheckUidResponse>('/interim/checkUid', { uid })
                .then((res) => {
                    if (!res.valid) {
                        commit('setSignupResponse', null)
                        commit('setSignupStorageData', null)
                    }

                    return res
                })
        },

        async validateRegLink(_, { token, packageName }) {
            return await axiosCore.post('/interim/getAgency', {
                token,
                packageName
            })
        },

        async getDefaultAgency() {
            return await axiosCore.get('/interim/getDefaultAgency')
        }
    }
}
