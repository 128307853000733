export interface Notification {
    text: string,
    id?: number,
    type?: 'success' | 'error',
    duration?: number
}

export interface Toast {
    id?: number,
    title?: string,
    type: 'default' | 'activity',
    duration?: number,
    text?: string,
    avatar?: string,
    accent?: string,
    actionText?: string,
    actionCallback?(...args: any): void
}

export enum ToastActions {
    'like' = 'like',
    'beLiked' = 'beLiked',
    'follow' = 'follow',
    'unfollow' = 'unfollow',
    'sendGift' = 'sendGift',
    'report' = 'report',
    'block' = 'block',
    'unblock' = 'unblock',
}

export enum ToastActivities {
    'gift' = 'gift',
    'missedCall' = 'missedCall',
    'newMessage' = 'newMessage',
}