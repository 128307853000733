export const clickOutsideDirective = {
    mounted: function (el, binding) {
        setTimeout(() => {
            el.clickOutsideEvent = function (event) {
                if (!(el == event.target || el.contains(event.target))) {
                    binding.value(event)
                }
            }
            document.body.addEventListener('click', el.clickOutsideEvent)
            document.body.addEventListener('touchstart', el.clickOutsideEvent)
        }, 0)
    },
    unmounted: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
        document.body.removeEventListener('touchstart', el.clickOutsideEvent)
    }
}