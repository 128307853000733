import { LocationQuery } from 'vue-router'

type StorageType = 'localStorage' | 'sessionStorage'

const debounce = (func: Function, delay: number) => {
    let timerId: ReturnType<typeof setTimeout> | null = null

    return () => {
        if (!timerId) {
            func()

            timerId = setTimeout(() => { timerId = null }, delay)
        }
    }
}

const storageJsonParser = (key: string, storageType: StorageType = 'localStorage'): any => {
    const storage = window[storageType]

    try {
        return JSON.parse(storage[key])
    } catch (err) {
        storage.removeItem(key)
        return null
    }
}

const copyToClipboard = (text: string) => {
    if (!navigator.clipboard) {
        return
    }

    navigator.clipboard.writeText(text)
        .then(() => { })
        .catch(() => { })
}

function getRandom(min: number, max: number): number {
    return min + Math.round(Math.random() * Math.floor(max - min))
}

function replaceQueryParamName({ link, originalName, replacingName }: { link: string, originalName: string, replacingName: string }): string {
    const url = new URL(link)

    const originalParamValue = url.searchParams.get(originalName)

    if (originalParamValue) {
        url.searchParams.append(replacingName, originalParamValue)
        url.searchParams.delete(originalName)
    }

    return url.href
}

function appendQueryParamsToURL(link: string, params: Record<string, string> | LocationQuery ): string {
    const url = new URL(link)

    for (const [key, value] of Object.entries(params)) {
        url.searchParams.append(key, String(value))
    }

    return url.href
}

function generateRandomLetters(length: number) {
    const letters = 'abcdefghijklmnopqrstuvwxyz'
    let randomLetters = ''

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * letters.length)
        randomLetters += letters[randomIndex]
    }

    return randomLetters
}

function generateEmail() {
    const currentDate = new Date()
    const day = currentDate.getDate().toString().padStart(2, '0')
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
    const hours = currentDate.getHours().toString().padStart(2, '0')
    const minutes = currentDate.getMinutes().toString().padStart(2, '0')

    const randomLettersFirstGap = generateRandomLetters(2)
    const randomLettersSecondGap = generateRandomLetters(3)

    const email = `${day}${randomLettersFirstGap}${month}${randomLettersSecondGap}${hours}${minutes}@livebalance.biz`
    return email
}

function generateRandomPassword() {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
    let password = ''

    for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length)
        password += characters[randomIndex]
    }

    return password
}

function getQueryParamsExcept(query: LocationQuery, except: string[]): LocationQuery {
    const params: LocationQuery = {}

    for (const paramName of Object.keys(query)) {
        if (!except.includes(paramName)) {
            params[paramName] = query[paramName]
        }
    }

    return params
}

function getQueryParamsInclude(searchParams: URLSearchParams, include: string[]): LocationQuery {
    const params: LocationQuery = {}

    include.forEach(paramName => {
        if (searchParams.has(paramName)) {
            params[paramName] = searchParams.get(paramName)
        }
    })

    return params
}

export function isEqual(a: any, b: any): boolean {
    if (a === b) return true
    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b
    if (a.prototype !== b.prototype) return false

    const keys = Object.keys(a)
    if (keys.length !== Object.keys(b).length) return false

    return keys.every((k) => isEqual(a[k], b[k]))
}

export {
    debounce,
    storageJsonParser,
    copyToClipboard,
    getRandom,
    replaceQueryParamName,
    appendQueryParamsToURL,
    generateEmail,
    generateRandomPassword,
    getQueryParamsExcept,
    getQueryParamsInclude
}