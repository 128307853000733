import { Module } from 'vuex'
import { RootState } from '@/store'
import { axiosCore } from '@/plugins/axios'

import {
    BillingData,
    PaymentMethodsInfo,
    PayoutRequestItem
} from '@/interfaces/payment'
import { AuthBody } from '@/interfaces/auth'

import authHelpers from '@/helpers/auth'
import { isEmpty } from '@/helpers/data'

export interface ProfileState {
    billingsInfo: PaymentMethodsInfo
    payoutRequestsList: PayoutRequestItem[]
    agencyInfo: any
}

export interface UpdateProfilePasswordData {
    password: string
    newPassword: string
}

export interface onboardingScreens {
    onboardingScreens?: string[]
}

export const profileStore: Module<ProfileState, RootState> = {
    state: {
        billingsInfo: {},
        payoutRequestsList: [],
        agencyInfo: {}
    },
    getters: {
        billingsInfo: (state) => state.billingsInfo,
        payoutRequestsList: (state) => state.payoutRequestsList
    },
    mutations: {
        setBillingsInfo(state, data) {
            state.billingsInfo = data
        },
        modifyBillingsInfo(state, data) {
            state.billingsInfo[data.method as keyof PaymentMethodsInfo] = {
                ...data
            }
        },
        setPayoutRequestsList(state, data) {
            state.payoutRequestsList = data
        },
        updateInfo(state, data) {
            state.agencyInfo = data;
        }
    },

    actions: {
        async getProfile({ commit }) {
            await axiosCore.get('/profile').then((data: any) => {
                const adminPanelAuthRoles = [
                    'manager',
                    'admin',
                    'owner',
                    'superadmin'
                ]

                if (adminPanelAuthRoles.includes(data.rolesGroup)) {
                    commit('setSupport', data)
                    return
                }

                commit('updateInfo', data);

                commit('updateProfile', data)
            })
        },

        async updateProfile({ commit }, payload) {
            await axiosCore.post('/profile', payload).then((data) => {
                commit('updateProfile', data)
            })
        },

        async loadOnboardingScreens(ctx) {
            const userId = ctx.getters.user.profile._id
            const screens: onboardingScreens = await axiosCore.get(
                '/userOnboardingScreens'
            )
            const onboardingScreens =
                screens?.onboardingScreens?.filter((item) => item) || []

            await localStorage.setItem(
                `onboardingScreens_${userId}`,
                JSON.stringify(onboardingScreens)
            )

            if (screens.onboardingScreens) {
                return screens.onboardingScreens
            }
        },

        async addOnboardingScreen(ctx, screen) {
            const userId = ctx.getters.user.profile._id
            const currentScreens: string =
                localStorage.getItem(`onboardingScreens_${userId}`) || ''
            const parsedScreens: string[] = currentScreens
                ? JSON.parse(currentScreens)
                : []
            localStorage.setItem(
                `onboardingScreens_${userId}`,
                JSON.stringify([...parsedScreens, screen])
            )
            await axiosCore.post('/userOnboardingScreens', { screen })
            location.reload()
        },

        async updateProfilePassword(
            { dispatch, rootGetters },
            data: UpdateProfilePasswordData
        ) {
            const user = rootGetters['user']

            const body: AuthBody = {
                method: 'ownemail',
                email: user.profile.email,
                password: data.password,
                deviceId: user.profile.deviceId
            }

            const packageName = await dispatch(
                'checkIsRegisteredAndGetPackageName',
                user.profile.email
            )

            if (packageName) {
                body.packageName = packageName
            }

            await axiosCore.post('/login', body).catch(() => {
                throw new Error('Password not match!')
            })

            await dispatch(
                'updatePassword',
                { password: data.newPassword },
                { root: true }
            ).catch(() => {
                throw new Error('Failure in the system')
            })
        },

        async getAgencyPaymentHistory({ commit }) {
            return await axiosCore
                .post('/agencyPayoutRequests')
                .then((data) => {
                    commit('setPayoutRequestsList', data)
                })
        },

        async createPaymentMethod({ dispatch }, payload) {
            return await axiosCore
                .post('/billingMethods/create', payload)
                .then(() => {
                    dispatch('getPaymentMethodsList')
                })
        },

        async editPaymentMethod({ commit }, payload) {
            return await axiosCore
                .post(
                    `/billingMethods/edit?billingMethodId=${payload.billingMethodId}`,
                    payload.form
                )
                .then(() => {
                    commit('modifyBillingsInfo', {
                        ...payload.form,
                        method: payload.method,
                        default: payload.default,
                        _id: payload.billingMethodId
                    })
                })
        },
        async getPaymentMethodsList({ rootGetters, commit }) {
            const { billingMethods }: { billingMethods: BillingData[] } =
                await axiosCore.get('/billingMethods/list', {
                    params: { userId: rootGetters.user.profile?._id }
                })
            const paymentMethodsInfo: PaymentMethodsInfo = {}
            billingMethods.map((item) => {
                paymentMethodsInfo[item.method as keyof PaymentMethodsInfo] = {
                    ...item
                }
            })
            commit('setBillingsInfo', paymentMethodsInfo)
        }
    }
}
