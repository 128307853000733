import { IObject } from '@/interfaces/common'

export interface SummedObject {
    [key: string]: number
}

export function getPropValue(data: IObject, prop: string): any {
    const propKeys = prop.split('.')

    return propKeys.reduce((obj, key) => {
        return typeof obj === 'object' ? obj[key] : obj
    }, data)
}

export function sumObjects(data: Array<any>): SummedObject {
    return data.reduce((acc: SummedObject, item) => {
        Object.entries(item).forEach(([key, value]) => {
            if (typeof value !== 'number') {
                return
            }

            if (key in acc) {
                acc[key] += value
            } else {
                acc[key] = value
            }
        })

        return acc
    }, {})
}

export function copyDeepSimple<T>(data: T): T {
    return JSON.parse(JSON.stringify(data))
}

export function sortByNumberField<T extends Record<string, any>>(data: T[], fieldName: string) {
    data.sort((a, b) => (b[fieldName] as number) - (a[fieldName] as number))
}

export function sortByDateField<T extends Record<string, any>>(data: T[], fieldName: string, sortOrder: 'asc' | 'desc' = 'desc') {
    data.sort((a, b) => {
        const dateA = new Date(a[fieldName]).getTime()
        const dateB = new Date(b[fieldName]).getTime()

        if (sortOrder === 'asc') {
            return dateA - dateB
        } else {
            return dateB - dateA
        }
    })
}

export function sortByStringField<T extends Record<string, any>>(data: T[], fieldName: string) {
    data.sort((a, b) => {
        if (b[fieldName] > a[fieldName]) {
            return -1
        }
        if (b[fieldName] < a[fieldName]) {
            return 1
        }
        return 0
    })
}

export function base64ToString(encodedText: string) {
    return new TextDecoder().decode(base64ToBytes(encodedText));
}

function base64ToBytes(base64: string) {
    const binString = atob(base64);
    return Uint8Array.from(binString, (c) => c.charCodeAt(0));
}

export function runAsyncFn(asyncFn: () => Promise<void>) {
    asyncFn()
}

export const isEmpty = (val: any) => val == null || !(Object.keys(val) || val).length

export function isObject(value: any) {
    return typeof value === 'object' && value !== null && !Array.isArray(value)
}