import { RouteRecordRaw } from 'vue-router'

const defaultLayout = 'desktop'
let routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('@/pages/desktop/index.vue'),
        meta: {
            title: 'general.dashboard',
            subheaderName: 'dashboard'
        }
    },
    {
        path: '/streamers',
        name: 'streamers',
        component: () => import('@/pages/desktop/streamers.vue'),
        meta: {
            title: 'general.streamers',
            subheaderName: 'streamers'
        }
    },
    {
        path: '/invites',
        name: 'invites',
        component: () => import('@/pages/desktop/invites.vue'),
        meta: {
            title: 'general.invites',
            subheaderName: 'invites'
        }
    },
    {
        path: '/logs',
        name: 'logs',
        component: () => import('@/pages/desktop/logs.vue'),
        meta: {
            title: 'general.logs',
            subheaderName: 'logs'
        }
    },
    {
        path: '/profile/:id',
        name: 'profile',
        component: () => import('@/pages/desktop/profile/_id.vue'),
        meta: {
            title: 'general.profile'
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@/pages/desktop/notifications.vue'),
        meta: {
            title: 'general.notifications'
        }
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('@/pages/desktop/faq.vue'),
        meta: {
            title: 'general.faq'
        }
    },
    {
        path: '/payments-targets',
        name: 'payments-targets',
        component: () => import('@/pages/desktop/payments-targets.vue'),
        meta: {
            title: 'general.payment_targets'
        }
    },
    {
        path: '/payments-history',
        name: 'payments-history',
        component: () => import('@/pages/desktop/payments-history.vue'),
        meta: {
            title: 'general.payment_history'
        }
    },
    {
        path: '/subagencies',
        name: 'subagencies',
        component: () => import('@/pages/desktop/subagencies.vue'),
        meta: {
            title: 'general.sub_agencies'
        }
    },
    {
        path: '/settings',
        name:'settings',
        redirect: '/settings/payment',
    },
    {
        path: '/settings/payment',
        name: 'settings_payment',
        component: () => import('@/pages/desktop/settings/payment.vue'),
        meta: {
            title: 'general.settings',
            parent: 'settings',
            subheaderName: 'settings'
        }
    },
    {
        path: '/settings/language',
        name: 'settings_language',
        component: () => import('@/pages/desktop/settings/language.vue'),
        meta: {
            title: 'general.settings',
            parent: 'settings',
            subheaderName: 'settings'
        }
    },
    {
        path: '/settings/personal-contacts',
        name: 'settings_personal-contacts',
        component: () => import('@/pages/desktop/settings/personal-contacts.vue'),
        meta: {
            title: 'general.settings',
            parent: 'settings',
            subheaderName: 'settings'
        }
    },
    {
        path: '/settings/passwords',
        name: 'settings_passwords',
        component: () => import('@/pages/desktop/settings/passwords.vue'),
        meta: {
            title: 'general.settings',
            parent: 'settings',
            subheaderName: 'settings'
        }
    },
    {
        path: '/settings/notifications',
        name: 'settings_notifications',
        component: () => import('@/pages/desktop/settings/notifications.vue'),
        meta: {
            title: 'general.settings',
            parent: 'settings',
            subheaderName: 'settings'
        }
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
]

routes = routes.map(item => {
    item.meta = item.meta || {}

    if (!item.meta.layout) {
        item.meta.layout = defaultLayout
    }

    return item
})

export default routes