import { IObject } from '@/interfaces/common'
import { DateRange } from '@/interfaces/data'
import { useLocalization } from '@/composables/localization'

interface FormatDateOptions {
    date: Date
    separator?: string
    format?: 'dd mm yy' | 'yy mm dd',
    withTime?: boolean
}

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const labels = {
    "min": "min",
    "hour": "hour",
    "day": "day",
    "week": "week",
    "month": "month",
    "year": "year",
    "ago": "ago"
}

function getUTCCopy(date: Date): Date {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0))
}

function padTo2Digits(num: number): string {
    return num.toString().padStart(2, '0')
}

const formatDate = ({ date, separator = '-', format = 'yy mm dd', withTime = false }: FormatDateOptions): string => {
    const dd: string = padTo2Digits(date.getDate())
    const mm: string = padTo2Digits(date.getMonth() + 1)
    const yy: number = date.getFullYear()
    const hours: number = date.getHours()
    const minutes: number = date.getUTCMinutes()
    const time = withTime ? ` ${padTo2Digits(hours)}:${padTo2Digits(minutes)}` : ''

    const dateObj: IObject = {
        dd,
        mm,
        yy
    }

    return `${format.split(' ').map(el => dateObj[el]).join(separator)}${time}`
}

function formatDateByMask(date: Date, format: string = 'YY-MM-dd'): string {
    const unitsMap = {
        YY: date.getFullYear(),
        yy: date.getFullYear() % 100,
        MM: padTo2Digits(date.getMonth() + 1),
        dd: padTo2Digits(date.getDate()),
        hh: padTo2Digits(date.getHours()),
        mm: padTo2Digits(date.getMinutes()),
        ss: padTo2Digits(date.getSeconds()),
        ms: padTo2Digits(date.getMilliseconds()),
    }

    return Object.entries(unitsMap).reduce((acc: string, [maskVar, maskValue]) => {
        return acc.replaceAll(maskVar, String(maskValue))
    }, format)
}

const dateToddhhmmss = (date: Date, separator: string = '.'): string => {
    const dd: string = date.getDate() + 'd'
    const hh: string = padTo2Digits(date.getHours()) + 'h'
    const mm: string = padTo2Digits(date.getMinutes()) + 'm'
    const ss: string = padTo2Digits(date.getSeconds()) + 's'

    return [dd, hh, mm, ss].join(separator)
}

const getCurrentUTCTime = () => {
    const date = new Date()
    const hours: number = date.getUTCHours()
    const minutes: number = date.getUTCMinutes()

    return `${formatDate({ date })} ${padTo2Digits(hours)}:${padTo2Digits(minutes)} UTC`
}
interface GetDateDifferenceParams {
    from: Date
    to: Date
    separator?: string
    format?: string
}

const getDateDifference = ({ from, to, separator = '.', format = 'dd hh mm ss' }: GetDateDifferenceParams): string => {
    const fromTime: number = from.getTime()
    const toTime: number = to.getTime()
    const distance: number = fromTime - toTime

    const dateObject: Record<string, string> = {
        dd: Math.floor(distance / (1000 * 60 * 60 * 24)) + 'd',
        hh: padTo2Digits(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))) + 'h',
        mm: padTo2Digits(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))) + 'm',
        ss: padTo2Digits(Math.floor((distance % (1000 * 60)) / 1000)) + 's'
    }

    return format.split(' ').map(el => dateObject[el]).join(separator)
}

function getDayOfCurrentWeek(day: number = 0): Date {
    // day from 0 to 6

    const curr: Date = new Date
    const firstDay: number = curr.getDate() - curr.getDay()
    const result: number = firstDay + day

    return new Date(curr.setDate(result))
}

function getUTCWeek(d: Date): { year: number, number: number } {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))

    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
    const weekNumber = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7)

    return {
        year: d.getUTCFullYear(),
        number: weekNumber,
    }
}

function getFirstWeekStart(year: number): Date {
    const date = new Date(year, 0, 1)

    while (true) {
        const { number: week } = getUTCWeek(date)

        if (week === 1) {
            break
        }

        date.setDate(date.getDate() + 1)
    }

    return date
}

function getWeekPeriod(date: Date): string {
    const week = getUTCWeek(date)

    return `${week.year}_${week.number}`
}

function getYesterday(): Date {
    const now = new Date()
    const yesterday = new Date(now.setDate(now.getDate() - 1))
    return yesterday
}

function getMonthRange(month: number): Date[] {
    const date = new Date()
    date.setMonth(month)

    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

    return [firstDay, lastDay]
}

function getDateOfISOWeek(week: number, year: number) {
    const simple = new Date(year, 0, 1 + (week - 1) * 7)
    const dayOfWeek = simple.getDay()
    const ISOweekStart = simple
    
    if (dayOfWeek <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1)
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay())
    }

    return ISOweekStart
}

function getWeekRange(date: Date, weekStart: number = 0): DateRange {
    const firstday = new Date(date.getTime())
    firstday.setDate(firstday.getDate() - firstday.getDay() + weekStart)
    
    const lastday = new Date(firstday.getTime())
    lastday.setDate(lastday.getDate() + 6)

    return [formatDate({ date: firstday }), formatDate({ date: lastday })]
}

function getWeekDays(date: Date, weekStart: number = 0): string[] {
    const currentDate = new Date(date.getTime())
    currentDate.setDate(currentDate.getDate() - currentDate.getDay() + weekStart)

    const days: string[] = []

    for (let i = 0; i <= 6; i++) {
        days.push(formatDate({ date: currentDate }))
        currentDate.setDate(currentDate.getDate() + 1)
    }

    return days
}

interface RelativeTimeParams {
    date: Date
    customLabels: Partial<typeof labels>
    withEnding: Boolean
}

function getRelativeTime(params: RelativeTimeParams): string {
    const secondInMs = 1000
    const minuteInMs = secondInMs * 60
    const hourInMs = minuteInMs * 60
    const dayInMs = hourInMs * 24
    const weekInMs = dayInMs * 7
    const monthInMs = dayInMs * 30
    const yearInMs = dayInMs * 365

    const timeLabels = {
        ...labels,
        ...params.customLabels
    }

    const getPluralEnding = (num: number, ending: string = 's'): string => {
        const { locale } = useLocalization()
        const isEnglishLang = locale.value === 'en'
        
        if (num > 1) {
            return isEnglishLang ? ending : ''
        }

        return ''
    }

    const msDiff = Date.now() - params.date.getTime()

    if (msDiff < 0) {
        return ''
    }

    if (msDiff < hourInMs) {
        return `${Math.floor(msDiff / minuteInMs) || 1} ${timeLabels['min']} ${timeLabels['ago']}`
    }

    if (msDiff < dayInMs) {
        const hours = Math.floor(msDiff / hourInMs)
        return `${hours} ${timeLabels['hour']}${getPluralEnding(hours)} ${timeLabels['ago']}`
    }

    if (msDiff < weekInMs) {
        const days = Math.floor(msDiff / dayInMs)
        return `${days} ${timeLabels['day']}${getPluralEnding(days)} ${timeLabels['ago']}`
    }

    if (msDiff < monthInMs) {
        const weeks = Math.floor(msDiff / weekInMs)
        return `${weeks} ${timeLabels['week']}${getPluralEnding(weeks)} ${timeLabels['ago']}`
    }

    if (msDiff < yearInMs) {
        const months = Math.floor(msDiff / monthInMs)
        return `${months} ${timeLabels['month']}${getPluralEnding(months)} ${timeLabels['ago']}`
    }

    const years = Math.floor(msDiff / yearInMs)
    return `${years} ${timeLabels['year']}${getPluralEnding(years)} ${timeLabels['ago']}`
}

function getDatesFromRange(startDate: Date, endDate: Date) {
    const dates = []
    let currentDate = startDate
    
    const addDays = (currentDate: Date, days: number) => {
        const date = new Date(currentDate)
        date.setDate(date.getDate() + days)
        return date
    }

    while (currentDate <= endDate) {
        dates.push(currentDate)
        currentDate = addDays(currentDate, 1)
    }

    return dates
}

function isWithinLastNDays(timestamp: string, days = 28) {
    const parsedDate: Date = new Date(timestamp)
    const currentDate: Date = new Date()
  
    const differenceInMilliseconds = currentDate.getTime() - parsedDate.getTime()
    const daysDifference = differenceInMilliseconds / (1000 * 3600 * 24)
  
    return daysDifference <= days
}

function formatTimeDuration(duration: number, separator: string = ':', strictTwoDigitMinutes: boolean = true): string {
    let minutes = String(Math.floor(duration / 60))

    if (minutes.length <= 1 && strictTwoDigitMinutes) {
        minutes = `0${minutes}`
    }

    let seconds = String(duration % 60)

    if (seconds.length <= 1) {
        seconds = `0${seconds}`
    }

    return `${minutes}${separator}${seconds}`
}

export {
    months,
    getUTCCopy,
    formatDate,
    formatDateByMask,
    dateToddhhmmss,
    padTo2Digits,
    getDateDifference,
    getDayOfCurrentWeek,
    getUTCWeek,
    getFirstWeekStart,
    getWeekPeriod,
    getYesterday,
    getMonthRange,
    getCurrentUTCTime,
    getDateOfISOWeek,
    getWeekRange,
    getRelativeTime,
    getWeekDays,
    getDatesFromRange,
    isWithinLastNDays,
    formatTimeDuration
}