import { Module } from 'vuex'
import { RootState } from '@/store'
import { axiosCore } from '@/plugins/axios'

export interface LogsState {
    banLogs: any[]
}

export const logsStore: Module<LogsState, RootState> = {
    state: {
        banLogs: [],
    },
    getters: {
        banLogs: state => state.banLogs,
    },
    mutations: {
        setBanLogs(state, logs: any[]) {
			state.banLogs = logs
		}
    },
    actions: {
        async loadBanLogs({ commit }) {
            await axiosCore.get<any, any[]>('/admin/matchingBanHistory')
                .then(logs => {
                    commit('setBanLogs', logs)
                })
        }
    },
}