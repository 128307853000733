import axios from 'axios'
import Cookie from 'js-cookie'
import { v4 as uuidv4 } from 'uuid'

const sessionExpireDuration = 30 * 60 * 1000 // 30 min

function checkIsSessionExpired() {
    return (
        !localStorage.iaSessionId ||
        Date.now() - sessionExpireDuration > Number(localStorage.iaLastActivity)
    )
}

function generateSessionId() {
    const newSessionId = uuidv4()

    localStorage.iaSessionId = newSessionId
    return newSessionId
}

export default class InternalAnalytics {
    CONFIG = {
        servicePort: 3016
    }
    untrackedActivities = []
    isUntrackedTimeoutSet = false

    constructor(hostname) {
        this.hostname = hostname
        this.axios = this.createAxiosInstance(hostname)
        this.sessionId = localStorage.iaSessionId
    }

    getQueryStringFromObject(params) {
        let queryString = ''
        for (let [key, value] of Object.entries(params)) {
            queryString += queryString ? '&' : '?'
            queryString += `${key}=${value}`
        }
        return queryString
    }

    loadLandingScript(landKey) {
        const lang = navigator.language || navigator.userLanguage
        let headElem = document.getElementsByTagName('head')
        headElem = headElem.length > 0 && headElem[0]

        if (!headElem) return

        const script = document.createElement('script')
        const queryParams = { ll: landKey, lang }
        const luid = Cookie.get('luid')

        if (luid) {
            queryParams.luid = luid
        }

        const locationQuery = new URLSearchParams(window.location.search)
        const clickIdParams = ['gclid', 'fbclid', 'af_sub5', 'fbc']

        for (let [key, value] of locationQuery.entries()) {
            if (clickIdParams.includes(key.toLowerCase())) {
                key = 'clickid'
            }
            queryParams[key] = value
        }

        script.src = `https://${this.hostname}:${this.CONFIG.servicePort}/land/limp.js`
        script.src += this.getQueryStringFromObject(queryParams)
        headElem.appendChild(script)
    }

    createAxiosInstance() {
        return axios.create({
            baseURL: `https://${this.hostname}:${this.CONFIG.servicePort}`
        })
    }

    setUntrackedTimeout() {
        this.isUntrackedTimeoutSet = true
        setTimeout(() => {
            this.isUntrackedTimeoutSet = false
            const untrackedActivities = this.untrackedActivities
            this.untrackedActivities = []
            untrackedActivities.forEach((item) => this.activity(item))
        }, 500)
    }

    getTrackingToken() {
        return window.simplyTrack && simplyTrack.getTt && simplyTrack.getTt()
    }

    generateSessionId() {
        const newSessionId = uuidv4()

        localStorage.iaSessionId = newSessionId
        this.sessionId = newSessionId
    }

    activity(data) {
        const { event, eventCategory, params = {} } = data

        if (checkIsSessionExpired()) {
            this.sessionId = generateSessionId()
        }

        params.sessionId = this.sessionId
        params['user_agent'] = navigator.userAgent

        localStorage.iaLastActivity = Date.now()

        try {
            if (!event) return

            if (window.simplyTrack && simplyTrack.activity) {
                simplyTrack.activity({
                    event,
                    eventCategory,
                    params
                })
            } else {
                this.untrackedActivities.push(data)
                if (!this.isUntrackedTimeoutSet) this.setUntrackedTimeout()
            }
        } catch (err) {
            console.error(err)
        }
    }

    asyncActivity(data) {
        return new Promise((resolve) => {
            this.activity(data)
            setTimeout(() => resolve(), 200)
        })
    }
}
