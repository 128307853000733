export enum PackageName {
    OLIVE = 'com.videochat.olive',
    TOPLIVE = 'com.toplive.chat',
    JOOLA = 'co.joola',
    CMC = 'com.callmechat',
    LOWINS = 'com.lowins.chat'
}

export enum BrandId {
    OLIVE = 1,
    CMC = 6,
    LOWINS = 7,
    TOPLIVE = 8,
    JOOLA = 10
}
