import { Earnings } from '@/interfaces/earnings'
import { GalleryPicture, Story, GesturePicture } from '@/interfaces/media'
import { Status } from '@/interfaces/data'


export interface InvitedStreamer {
    [key: string]: any
}

export interface Avatar {
    picture: string
    croppedPicture: string
    pictureCropped: string
}

export enum RolesGroup {
    user = 'user',
    goddess = 'goddess',
    pendingGoddess = 'pendingGoddess',
    agency = 'agency',
}

export type Gender = 'male' | 'female'

export type Identity = 'male' | 'transmale' | 'nonbinarymale' | 'female' | 'transfemale' | 'nonbinaryfemale' | 'nonbinary' | 'intersex'

export type PaidStatus = 'organic' | 'paid'

export interface AuthData {
    token: string
}

export interface User {
    profile: Profile
    authData: AuthData
}

export interface Profile {
    _id: string
    humanReadableId: number
    name?: string
    birthday?: string
    avatar?: Avatar
    avatarOnModeration?: Avatar
    age?: number
    status?: Status
    rolesGroup: RolesGroup
    earning?: number
    likesCount?: number
    last_ip_country?: string
    premium?: boolean
    lc?: number
    lt?: number
    lp?: number
    coins?: number
    gender: Gender
    email?: string
    phone?: string
    blocked?: boolean
    banned?: boolean
    coinsTotal?: number
    expectedSource?: boolean
    matchingBans?: number
    earnings?: Earnings
    agencyName?: string
    agency?: string
    gallery?: GalleryPicture[]
    videoGallery?: Story[]
    gestures?: GesturePicture[]
    contactEmail?: string
    contactPhone?: string
    agencyCommissionPercent: number
}

export interface SelfProfile extends Profile {
    brandId: number
    emailConfirmed: boolean
    messenger: string
    phone: string
    agency?: string
    billingInfo?: {
        country?: string,
        city?: string,
        address?: string,
        phone?: string,
        zipCode?: string,
    }
}

export interface SupportContacts {
    email: string
    name: string
    phone: string
    telegramNickname: string
}