import { computed, Ref, watch } from 'vue'
import { useStore } from 'vuex'
import { RouteRecordName, useRoute, useRouter } from 'vue-router'
import { isProd } from '@/helpers/env'
import { Tab } from '@/interfaces/ui'
import { Filter } from '@/interfaces/filter'
import { useLocalization } from '@/composables/localization'

export enum TabType {
    'filter' = 'filter',
    'router' = 'router'
}
interface OptionsByRoute {
    justOrderedPeriods?: boolean
    filters?: Filter[]
    tabs?: Tab[]
    tabType?: TabType
    withLevels?: boolean
}

interface SubheaderOptions {
    [key: string]: OptionsByRoute
}

interface SubheaderComposable {
    isShowSubheader: Ref<boolean>
    subheaderOptions: Ref<OptionsByRoute | null>
    currentTab: Ref<number | string | null>
    tabs: Ref<Tab[]>
    subheaderName: Ref<string>
    initRouteTab: () => void
}

export function useSubheader(): SubheaderComposable {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const { t } = useLocalization()

    const subheaderOptionsByName = computed<SubheaderOptions>(() => ({
        dashboard: {
            filters: ['period'], //ADM-1469
            justOrderedPeriods: true,
            withLevels: true
        },
        logs: {
            filters: ['date']
        },
        invites: {
            filters: ['date']
        },
        streamers: {
            tabType: TabType.filter, //ADM-1469
            filters: ['active'], //ADM-1469
            justOrderedPeriods: true, //ADM-1469
            tabs: [
                { text: t('general.active'), value: 'active' },
                { text: t('general.all'), value: 'all' },
            ]
        },
        settings: {
            tabType: TabType.router,
            tabs: [
                {
                    text: t('general.payment_details'),
                    value: 'settings_payment'
                },
                {
                    text: t('general.personal_contacts'),
                    value: 'settings_personal-contacts'
                },
                {
                    text: t('general.email_and_password'),
                    value: 'settings_passwords'
                },
                {
                    text: t('general.notifications'),
                    value: 'settings_notifications'
                },
                { text: t('general.language'), value: 'settings_language' }
            ].filter((btn) => {
                const hiddenOnProd = ['settings_notifications']

                return !isProd || !hiddenOnProd.includes(btn.value)
            })
        }
    }))

    const subheaderName = computed<string>(
        () => route.meta?.subheaderName || ''
    )
    const isShowSubheader = computed<boolean>(() => !!subheaderName.value)
    const subheaderOptions = computed(() => {
        if (!isShowSubheader.value) {
            return null
        }

        return subheaderOptionsByName.value[subheaderName.value]
    })

    const tabs = computed<Tab[]>(() => subheaderOptions.value?.tabs || [])
    const currentTab = computed<string | number | null>(
        () => store.getters.subheaderTab
    )

    watch(tabs, (val) => {
        initRouteTab()
    })

    watch(currentTab, (val) => {
        if (!val) {
            return
        }

        if (subheaderOptions.value?.tabType === TabType.router) {
            router.push({ name: val as RouteRecordName })
        }
    })

    function initRouteTab() {
        if (subheaderOptions.value?.tabType === TabType.router) {
            store.commit('setSubheaderTab', route.name)
            return
        }

        if (!currentTab.value) {
            store.commit('setSubheaderTab', tabs.value[0]?.value ?? null)
            return
        }

        store.commit('setSubheaderTab', tabs.value[0]?.value ?? null)
    }

    return {
        subheaderName,
        isShowSubheader,
        subheaderOptions,
        currentTab,
        tabs,
        initRouteTab
    }
}
