import { computed, Ref } from 'vue'
import { useStore } from 'vuex'
import { getAge } from '@/helpers/user'
import { SelfProfile, Avatar, Profile } from '@/interfaces/user'

interface TestGroups {
    [key: string]: string
}

export interface UserComposable {
    testGroups: Ref<TestGroups>
    isAuth: Ref<boolean>
    profile: Ref<SelfProfile>
    coins: Ref<number>
    userAvatar: Ref<Avatar | undefined>
    userAvatarSrc: Ref<string>
    coinIcon: string
    userAgency: Ref<SelfProfile>
    isLimitedPermission: Ref<boolean>
    isSupportMode: Ref<boolean>
    isSubAgency: Ref<boolean>
    isSubAgencyElementsHidden: Ref<boolean>
    getAvatarPhoto: (avatar?: Avatar, placeholder?: string) => string
    getAge: (date: string) => number
    formatUserName: (name: string) => string
    logout: () => void
}

export function useUser(): UserComposable {
    const store = useStore()
    const testGroups = computed<TestGroups>(() => store.getters.testGroups)

    const getAvatarPhoto = (avatar?: Avatar, placeholder: string = '/img/avatar_placeholder.png'): string => {
        if (!avatar) return placeholder
        return avatar.pictureCropped || avatar.picture || placeholder
    }

    const isAuth = computed<boolean>(() => store.getters.isAuth)
    const profile = computed<SelfProfile>(() => store.getters.user.profile || {})
    const coins = computed<number>(() => profile.value.coins || 0)
    const userAvatar = computed<Avatar | undefined>(() => profile.value.avatar || profile.value.avatarOnModeration)
    const userAvatarSrc = computed<string>(() => getAvatarPhoto(userAvatar.value))
    const userAgency = computed<SelfProfile>(() => store.getters.matchedProfiles[profile.value.agency || ''])
    const coinIcon = 'fan-point'
    const isLimitedPermission = computed<boolean>(() => store.getters.user.profile.abTests?.includes('LATAM_Agency|true'))
    const isSupportMode = computed<boolean>(() => store.getters.support)
    const isSubAgency = computed<boolean>(() => store.getters.user.profile.abTests?.includes('Sub_Agency|true'))
    const isSubAgencyElementsHidden = computed<boolean>(() => isSubAgency.value && !isSupportMode.value)

    function formatUserName(name: string): string {
        if (!name) return ''

        return name.slice(0,15) + (name.length > 15 ? '...' : '')
    }

    async function logout() {
        await store.dispatch('logout')
    }

    return {
        testGroups,
        isAuth,
        profile,
        coins,
        userAvatar,
        userAvatarSrc,
        coinIcon,
        userAgency,
        isLimitedPermission,
        isSupportMode,
        isSubAgency,
        isSubAgencyElementsHidden,
        getAvatarPhoto,
        getAge,
        formatUserName,
        logout
    }
}