import { createApp } from 'vue'
import App from './App.vue'
import config from '@/config'
import router from './router'
import store from './store'
import { clickOutsideDirective } from '@/directives/clickOutside'
import localization from '@/plugins/localization'
import swUnregistering from '@/helpers/swUnregistering'
import { redirectPrideBalanceOldHostname } from '@/helpers/location'

import './assets/scss/main.scss'

redirectPrideBalanceOldHostname()

const app = createApp(App);

(async () => {
    swUnregistering()

    app.directive('click-outside', clickOutsideDirective)

    app.use(localization, {
        locale: config.locales.find(locale => locale.default)?.code,
        locales: config.locales.map(locale => locale.code),
        scopes: [
            { name: 'global', dirPath: 'locales', cached: true },
            { name: 'faq', dirPath: 'locales/faq', cached: true },
        ],
    })

    app.use(store)
    app.use(router)

    app.mount('#app')
})()

export default app
