export enum DeviceOs {
    IOS = 'ios',
    Android = 'android',
    WEB = 'web'
}

export function getAppLang(): string {
    const defaultLang = 'en'

    if (navigator.language) {
        return navigator.language.split('-')[0]
    } else if (navigator.languages && navigator.languages.length > 0) {
        return navigator.languages[0].split('-')[0]
    }

    return defaultLang
}

export const isAppleMobileDevice = [
    /iPhone/i,
    /iPad/i,
    /iPod/i
].some(item => navigator.userAgent.match(item))

export const isAppleDesktopDevice = [
    /Macintosh/i,
    /MacIntel/i,
    /MacPPC/i,
    /Mac68K/i
].some(item => navigator.platform.match(item))

export const isAppleDevice = isAppleMobileDevice || isAppleDesktopDevice

export const isMobileDevice = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
].some(item => navigator.userAgent.match(item))

export const isMobile = window.innerWidth < 1280 || isMobileDevice

export const isMozilla = navigator.userAgent.toLowerCase().includes('firefox')

export function getDeviceOs(): DeviceOs {
    if (!isMobileDevice) {
        return DeviceOs.WEB
    }

    if (isAppleMobileDevice) {
        return DeviceOs.IOS
    }

    return DeviceOs.Android
}

export function isMobileOs(os: DeviceOs.IOS | DeviceOs.Android | DeviceOs.WEB) {
    return [DeviceOs.IOS, DeviceOs.Android].includes(os)
}