export interface GeoInfo {
    fl?: string
    h?: string
    ip?: string
    ts?: string
    visit_scheme?: string
    uag?: string
    colo?: string
    sliver?: string
    http?: string
    loc?: string
    tls?: string
    sni?: string
    warp?: string
    gateway?: string
    rbi?: string
    kex?: string
}

const geoInfo: GeoInfo = {}

export async function getGeoInfo() {
    if (Object.keys(geoInfo).length > 0) {
        return geoInfo
    }

    const locationInfoURL = 'https://www.cloudflare.com/cdn-cgi/trace'

    await fetch(locationInfoURL)
        .then(res => res.text())
        .then(data => {
            for (const line of data.split('\n')) {
                const [key, value] = line.split('=')

                geoInfo[key as keyof GeoInfo] = value
            }
        })
        .catch(console.warn)

    return geoInfo
}

getGeoInfo()